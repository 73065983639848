import { Directive, EventEmitter, Injectable, OnDestroy } from "@angular/core";
import {
    ContextualBalloon, Plugin, Editor, Widget, toWidget, ClickObserver,
    ButtonView, viewToModelPositionOutsideModelElement, ViewDocumentClickEvent,
    clickOutsideHandler
} from "ckeditor5";
import { ClausesEditionViewUtilsService } from "./utils/clauses-edition-view-utils.service";
import { ClauseModel } from "./models/clause-model";
import ClauseCommand from "./clauses-command";
import { ClausesDataViewUtilsService } from "./utils/clauses-data-view-utils.service";
import ClauseBalloonFormView from "./ui/clause-balloon-view.directive";
import ClauseDeleteCommand from "./clauses-delete-command";
import ClauseGoToCommand from "./clauses-goto-command";
import { ClausesBalloonUtilService } from "./utils/clause-balloon-utils.service";
import { MatDialogRef } from '@angular/material/dialog';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { GenericDialogConfig } from "src/app/core/shared/models/generic-dialog-config.model";
import { ListClauseModalComponent } from 'src/app/shared/components/wysiwyg-editor/list-clause-modal/list-clause-modal.component';
import { dialogTypes } from "../../../ctbox-generic-dialog-data/ctbox-generic-dialog-data.component";
import { ClauseConstants } from "./models/clause-constants";
import { ITransformCleanHtmlService } from "src/app/core/shared/services/transform-clean-html/transform-clean-html.service.interface";
import { IClausesNavigationService } from 'src/app/core/standard/services/clauses/clause-navigation/clauses-navigation.service.interface';


@Directive({
    selector: 'clauses-plugin',
})
@Injectable({
    providedIn: 'root'
  })
export class ClausesPlugin extends Plugin implements OnDestroy {

    public static genericDialog: GenericDialogService;
    public static cleanHtmlService: ITransformCleanHtmlService;
    public static clausesNavigationService: IClausesNavigationService;

    private onDestroy$ = new EventEmitter<void>();
    private editorViewUtilsService: ClausesEditionViewUtilsService;
    private dataViewUtilsService: ClausesDataViewUtilsService;
    private clauseBallonService: ClausesBalloonUtilService;

    private balloonView: ClauseBalloonFormView;

    private lastDialog: MatDialogRef<any, any>;

    private balloon!: ContextualBalloon;

    constructor(
        editor: Editor,
    ) {
        super(editor);
        this.editorViewUtilsService = new ClausesEditionViewUtilsService();
        this.dataViewUtilsService = new ClausesDataViewUtilsService();
        this.clauseBallonService = new ClausesBalloonUtilService();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public static get requires() {
        return [Widget, ContextualBalloon];
    }
    public static get pluginName() {
        return ClauseConstants.CLAUSE_PLUGIN_NAME;
    }

    public static get pluginToolbarElementName() {
        return ClauseConstants.TOOLBAR_BUTTON_NAME_CLAUSES;
    }


    public static get toolbarButtonName() {
        return ClauseConstants.TOOLBAR_BUTTON_NAME_CLAUSES;
    }

    public init(): void {
        this.defineSchema();
        this.defineConverters();
        this.defineCommands();
        this.defineMapper();
        this.defineUI();
    }

      public showUI(idClauseValue?: string, forceVisible: boolean = false,): void {
          if (!this.balloonView) {
              this.createBalloonView(idClauseValue);
          }

          if (this.balloon.hasView(this.balloonView)) {
            this.balloon.remove(this.balloonView!);
          }

          this.balloon.add({
            view: this.balloonView!,
            position: this.clauseBallonService.getBalloonPositionData(this.editor),
          });
      }

    private defineSchema(): void {
        const schema = this.editor.model.schema;

        schema.register(ClauseConstants.CLAUSE_LABEL_MODEL, {
            inheritAllFrom: '$blockObject',
            allowContentOf: '$root',
            allowAttributes: ['id', 'content']
        });
    }

    private defineConverters(): void {
        this.configureConverterFromDataViewToModel();
        this.configureConverterFromModelToDataView();
        this.configureConverterFromModelToEditorView();
    }

    private defineMapper() {
        const editor = this.editor;
        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(ClauseConstants.CLAUSE_IN_EDITOR_CLASS))
        );
    }

    private defineCommands() {
        this.editor.commands.add(
            ClauseConstants.COMMAND_NAME_INSERT_CLAUSE,
            new ClauseCommand(this.editor)
        );

        this.editor.commands.add(
            ClauseConstants.COMMAND_NAME_DELETE_CLAUSE,
            new ClauseDeleteCommand(this.editor)
        );

        this.editor.commands.add(
            ClauseConstants.COMMAND_NAME_GO_TO_CLAUSE,
            new ClauseGoToCommand(this.editor)
        );
    }

    private defineUI() {
        const editor = this.editor;
        editor.editing.view.addObserver(ClickObserver);
        this.balloon = editor.plugins.get(ContextualBalloon);
        this.enableBalloonActivators();

        editor.ui.componentFactory.add(ClausesPlugin.pluginToolbarElementName,
            (locale) => {
                const button = new ButtonView(locale);

                button.set({
                    label: ClauseConstants.TOOLBAR_NAME_STRING,
                    icon: ClauseConstants.CLAUSE_EDITOR_ICON,
                    tooltip: true,
                    withText: true,
                });

                const self = this;


                button.on("execute", () => {
                    self.openInsertClauseFromLibrary();
                });

                return button;
            });
    }

    private configureConverterFromDataViewToModel(): void {
        const conversion = this.editor.conversion;

        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [ClauseConstants.CLAUSE_IN_EDITOR_CLASS],
            },
            model: (viewElement, { writer: modelWriter }) => {
                const id = viewElement.getAttribute('id');
                const content = viewElement.getAttribute('content');
                return modelWriter.createElement(ClauseConstants.CLAUSE_LABEL_MODEL, { 'id': id, 'content': content });
            },
        });
    }

    private configureConverterFromModelToDataView(): void {
        const conversion = this.editor.conversion;

        conversion.for("dataDowncast").elementToElement({
            model: ClauseConstants.CLAUSE_LABEL_MODEL,
            view: (modelItem, { writer: viewWriter }) => {
                return this.dataViewUtilsService.createClauseEditorView(modelItem, viewWriter);
            }
        });
    }

    private configureConverterFromModelToEditorView(): void {
        const conversion = this.editor.conversion;

        conversion.for("editingDowncast").elementToElement({
            model: ClauseConstants.CLAUSE_LABEL_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorViewUtilsService.createClauseEditorView(modelItem, viewWriter);

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private enableBalloonActivators(): void {
        const viewDocument = this.editor.editing.view.document;

        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', () => {
            const parentClause = this.editorViewUtilsService.getSelectedClauseElement(this.editor);
            if (parentClause) {
                const idClause = parentClause.getAttribute("id");
                this.showUI(idClause);
                return;
            }
        });
    }

    private getBalloonView(idClauseValue?: string): ClauseBalloonFormView {
        const editor = this.editor;
        const balloonView = new ClauseBalloonFormView(editor.locale);

        balloonView.deleteButtonView.on('execute', () => {
            editor.execute(ClauseConstants.COMMAND_NAME_DELETE_CLAUSE);
            this.hideUI();
        });

        balloonView.goToClauseButtonView.on('execute', () => {
            editor.execute(ClauseConstants.COMMAND_NAME_GO_TO_CLAUSE, idClauseValue);
            this.hideUI();
        });

        return balloonView;
    }

    private createBalloonView(idClauseValue?: string) {
        this.balloonView = this.getBalloonView(idClauseValue);
        this.enableUserBalloonInteractions(this.editor, this.balloonView);
    }

    private enableUserBalloonInteractions(editor: Editor, formView: ClauseBalloonFormView): void {
        //this.moveWithTabInBalloon(editor, formView);
        this.configureCloseBallonWithOutsideClick();
    }

    private configureCloseBallonWithOutsideClick() : void {
      clickOutsideHandler({
          emitter: this.balloonView!,
          activator: () => this.clauseBallonService.isBalloonInPanel(this.balloon, this.balloonView),
          contextElements: () => [this.balloon.view.element!],
          callback: () => this.hideUI(),
      });
    }


    private hideUI() : void {
      if (this.clauseBallonService.isBalloonInPanel(this.balloon, this.balloonView)) {
          this.balloon.remove(this.balloonView);
      }

        const editor = this.editor;
        this.removeBalloonObservers(editor);

        editor.editing.view.focus();

      this.clauseBallonService.removeBalloonView(this.editor, this.balloon, this.balloonView);

        if (this.balloon.hasView(this.balloonView)) {
            this.balloon.remove(this.balloonView!);
        }

      this.clauseBallonService.hideFakeVisualSelection(this.editor);
    }

    private removeBalloonObservers(editor: Editor): void {
        this.stopListening(editor.ui, "update");
        this.stopListening(this.balloon, "change:visibleView");
    }

    public openInsertClauseFromLibrary() {
        const editor = this.editor;
        const config = this.getListModalConfig();
        const data: any = {
            template: ListClauseModalComponent,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogButton: $localize`:@@InsertarClausulaDesdeBibliotecaBoton:Insertar seleccionados`,
            dialogCloseButon: $localize`:@@Cancelar:Cancelar`,
            dialogTypes: dialogTypes.NotOverflow,
            dialogTitle: $localize`:@@InsertarClausulaDesdeBiblioteca:Insertar una cláusula desde la biblioteca`,
            primaryButtonContentObservableName: 'isValidSubscription',
            dialogContentGetResultPropertyName: 'selectedClauses',
            dialogContentGetCloseResultPropertyName: 'selectedClauses',
        };

        this.lastDialog = ClausesPlugin.genericDialog.openTemplateWithConfigAndData(data.template, config, data);

        this.lastDialog.afterClosed().subscribe((result) => {

            if (result === null) {
                return;
            }
            this.insertSeveralClauses(result);
        });
    }

    public getListModalConfig(): GenericDialogConfig {
        const config = ClausesPlugin.genericDialog.getDefaultDialogConfig();
        config.width = '50vw';
        config.height = '90vh';
        return config;
    }

    private insertSeveralClauses(clausesArray) {
        for (let i = 0; i < clausesArray.length; i++) {
            this.insertClause(clausesArray[i]);
        }
    }

    private insertClause(clauseInfo) {

        const id = clauseInfo.id;

        const content = ClausesPlugin.cleanHtmlService.cleanHeader(clauseInfo.description)
        const clause: ClauseModel = {
            id,
            content
        };

        this.editor.execute(ClauseConstants.COMMAND_NAME_INSERT_CLAUSE, clause);
    }

}
