import { Injectable } from '@angular/core';
import { DowncastWriter, ViewContainerElement } from 'ckeditor5';
import { ClauseConstants } from '../models/clause-constants';

@Injectable({
    providedIn: 'root'
})
export class ClausesDataViewUtilsService {

    constructor() { }

    public createClauseEditorView(modelItem: any, viewWriter: DowncastWriter): any {
        const id = modelItem.getAttribute("id");
        const container = viewWriter.createContainerElement('div', { class: [ClauseConstants.CLAUSE_CLASS_DATA_VIEW], id });
        return container;
    }
}
