import { Directive, EventEmitter, OnDestroy } from "@angular/core";
import {ButtonView, ClickObserver, ContextualBalloon, Dialog, Editor, Plugin, ViewDocument, ViewDocumentClickEvent,
        ViewElement, Widget, clickOutsideHandler, toWidget, viewToModelPositionOutsideModelElement
} from "ckeditor5";
import SignatureCommand from "./signature-command";
import SignatureConfigComponent, {SignatureFormValidatorCallback} from "./signature-config/signature-form-view.directive";
import { SignatureModel } from "./models/signature-model";
import type { PositionOptions } from "ckeditor5/src/utils.js";

import "src/css/signatureInEditor.css";
import { SignatureUtilsService } from "./signature-utils.service";
import SignatureFormView from "./signature-config/signature-form-view.directive";

@Directive({
    selector: "signature-plugin",
})

export class SignaturePlugin extends Plugin implements OnDestroy {

    private SIGNATURE_CLASS = "signature-in-editor";
    private ATTRIBUTE_ROLE_IS_STORED = "signature-title";
    private SIGNATURE_ROLE_BASE = "Firmante ";
    private readonly iconSignature =
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M560.9 136L536 111.1c-20.25-20.25-53-20.25-73.25 0L303.5 269.8C273.4 299.8 256.1 341.3 256 383.8c-3.875-.25-7.5-2.387-9.375-6.262c-11.88-23.88-46.25-30.38-66-14.12l-13.88-41.63C163.5 311.9 154.4 305.3 144 305.3s-19.5 6.625-22.75 16.5L103 376.4C101.5 381 97.25 384 92.38 384H80C71.13 384 64 391.1 64 400S71.13 416 80 416h12.4c18.62 0 35.1-11.88 40.98-29.5L144 354.6L160.8 405C165.4 418.6 184 419.9 190.3 407.1l7.749-15.38c4-8 15.69-8.5 20.19 .375C225.4 406.6 239.9 415.7 256 415.9L288 416c66.84 0 112.1-46.3 114.1-47.5l158.8-159.3C570.6 199.5 576 186.4 576 172.6C576 158.8 570.6 145.8 560.9 136zM379.5 346C355.3 370.3 322.4 383.9 288 383.9c0-34.38 13.75-67.32 37.1-91.44l120.6-119.9l52.75 52.75L379.5 346zM538.3 186.6L517 207.8L464.3 155l21.12-21.25c7.75-7.625 20.25-7.749 28 0l24.88 24.88C545.9 166.4 545.9 178.9 538.3 186.6zM364.4 448c-6.629 0-13.1 3.795-15.2 10.1C344.1 470.8 333 480 318.1 480h-255.2c-17.62 0-31.89-14.33-31.89-32V64c0-17.67 14.28-32 31.89-32h127.6v112c0 26.51 21.42 48 47.84 48h64.07c8.652 0 15.66-7.037 15.66-15.72V175.7C318.1 167 311.9 160 303.3 160H239.2C230.4 160 223.3 152.8 223.3 144V34.08c4.461 1.566 8.637 3.846 12.08 7.299l89.85 90.14c6.117 6.139 16.04 6.139 22.15 0L347.7 131.1c6.117-6.139 6.117-16.09 0-22.23L257.9 18.75C245.9 6.742 229.7 0 212.8 0H63.93C28.7 0 0 28.65 0 64l.0065 384c0 35.35 28.7 64 63.93 64h255c28.2 0 52.12-18.36 60.55-43.8C382.8 458.2 374.9 448 364.4 448z"/></svg>';
    private VISUAL_SELECTION_MARKER_NAME = "signature";
    private balloon!: ContextualBalloon;
    private formView: SignatureConfigComponent;
    private onDestroy$ = new EventEmitter<void>();
    private utilsService: SignatureUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.utilsService = new SignatureUtilsService();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public static get pluginName(): string {
        return "Signature" as const;
    }

    public static get pluginModelName(): string {
        return "signature" as const;
    }

    public static get pluginToolbarElementName(): string {
        return "signature" as const;
    }

    public static get requires() {
        return [Widget, ContextualBalloon, Dialog];
    }

    public static get toolbarButtonName() {
        return "Firma" as const;
    }

    public static get commandName() {
        return "signature" as const;
    }

    public init(): void {
        this.defineSchema();
        this.defineConverters();
        this.defineCommands();
        this.defineMapper();
        this.defineUI();
    }

    public showUI(defaultValue?: string, defaultSignatureId?: string, forceVisible: boolean = false): void {
        if (!this.formView) {
            this.createViews(defaultValue, defaultSignatureId);
        }

        const signatureElement = this.utilsService.getSelectedSignatureElement(this.editor);

        if (!signatureElement) {
            this.showFakeVisualSelection();
        }

        this.addFormView(defaultValue, defaultSignatureId);

        if (forceVisible) {
            this.balloon.showStack("main");
        }

        this.startUpdatingUI();
    }

    private startUpdatingUI(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        let prevSelectedSignature = this.utilsService.getSelectedSignatureElement(this.editor);
        let prevSelectionParent = getSelectionParent();

        const update = () => {
            const selectedSignature = this.utilsService.getSelectedSignatureElement(this.editor);
            const selectionParent = getSelectionParent();

            // Hide the panel if:
            //
            // * the selection went out of the EXISTING signature element. E.g. user moved the caret out
            //   of the link,
            // * the selection went to a different parent when creating a NEW signature. E.g. someone
            //   else modified the document.
            // * the selection has expanded (e.g. displaying signature actions then pressing SHIFT+Right arrow).
            //
            // Note: #_getSelectedLinkElement will return a link for a non-collapsed selection only
            // when fully selected.
            if ((prevSelectedSignature && !selectedSignature) ||
                (!prevSelectedSignature && selectionParent !== prevSelectionParent)) {
                this.hideUI();
            }
            // Update the position of the panel when:
            //  * Signature panel is in the visible stack
            //  * the selection remains in the original signature element,
            //  * there was no signature element in the first place, i.e. creating a new signature
            else if (this.isUIVisible()) {
                // If still in a signature element, simply update the position of the balloon.
                // If there was no signature (e.g. inserting one), the balloon must be moved
                // to the new position in the editing view (a new native DOM range).
                this.balloon.updatePosition(this.getBalloonPositionData());
            }

            prevSelectedSignature = selectedSignature;
            prevSelectionParent = selectionParent;
        };

        function getSelectionParent() {
            return viewDocument.selection.focus!.getAncestors()
                .reverse()
                .find( ( node ): node is ViewElement => node.is( 'element' ) );
        }

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
      }

    private showFakeVisualSelection(): void {
        const model = this.editor.model;

        model.change((writer) => {
            const range = model.document.selection.getFirstRange()!;

            if (model.markers.has(this.VISUAL_SELECTION_MARKER_NAME)) {
                writer.updateMarker(this.VISUAL_SELECTION_MARKER_NAME, {
                    range,
                });
            } else if (range.start.isAtEnd) {
                const startPosition = range.start.getLastMatchingPosition(
                    ({ item }) => !model.schema.isContent(item),
                    { boundaries: range }
                );

                writer.addMarker(this.VISUAL_SELECTION_MARKER_NAME, {
                    usingOperation: false,
                    affectsData: false,
                    range: writer.createRange(startPosition, range.end),
                });
            } else {
                writer.addMarker(this.VISUAL_SELECTION_MARKER_NAME, {
                    usingOperation: false,
                    affectsData: false,
                    range,
                });
            }

        });
    }

    /**
     * Adds the {@link #actionsView} to the {@link #_balloon}.
     *
     * @internal
     */
    public addActionsView(defaultValue?: string): void {
        if (this.formView) {
            this.addFormView(defaultValue);
            return;
        }

        this.createViews(defaultValue);
        if (this.areActionsInPanel()) {
            return;
        }

        this.balloon.add({
            view: this.formView!,
            position: this.getBalloonPositionData(),
        });
    }

    /**
     * Adds the {@link #formView} to the {@link #_balloon}.
     */
    private addFormView(defaultValue?: string, defaultId?: string): void {
        if (!this.formView) {
            this.createViews(defaultValue);
        }

        if (this.isFormInPanel()) {
            const isSameSignatureEditing =
                this.formView.signatureId !== defaultId;
            if (isSameSignatureEditing) {
                this.formView!.resetFormStatus();
                this.formView.role = !!defaultValue ? defaultValue : "";
                this.formView.signatureId = defaultId;
            }
            return;
        }

        this.formView!.resetFormStatus();
        this.formView.role = !!defaultValue ? defaultValue : "";
        this.formView.signatureId = defaultId;

        this.balloon.add({
            view: this.formView!,
            position: this.getBalloonPositionData(),
        });

        // Select input when form view is currently visible.
        if (this.balloon.visibleView === this.formView) {
            this.formView!.roleInputView.fieldView.select();
        }
    }

    private defineSchema(): void {
        const schema = this.editor.model.schema;

        schema.register(SignaturePlugin.pluginModelName, {
            inheritAllFrom: "$blockObject",
            allowAttributes: [this.ATTRIBUTE_ROLE_IS_STORED, "id"],
        });
    }

    private defineConverters(): void {
        this.configureConverterFromDataViewToModel();
        this.configureConverterFromModelToDataView();
        this.configureConverterFromModelToEditorView();
    }

    private defineMapper(): void {
        const editor = this.editor;
        editor.editing.mapper.on(
            "viewToModelPosition",
            viewToModelPositionOutsideModelElement(
                this.editor.model,
                (viewElement) => viewElement.hasClass(this.SIGNATURE_CLASS)
            )
        );
    }

    private defineCommands(): void {
        this.editor.commands.add(
            SignaturePlugin.commandName,
            new SignatureCommand(this.editor)
        );
    }

    private defineUI(): void {
        const editor = this.editor;
        editor.editing.view.addObserver(ClickObserver);
        this.balloon = editor.plugins.get(ContextualBalloon);
        this.enableBalloonActivators();

        editor.ui.componentFactory.add(
            SignaturePlugin.pluginToolbarElementName,
            (locale) => {
                const button = new ButtonView(locale);

                button.set({
                    label: SignaturePlugin.toolbarButtonName,
                    icon: this.iconSignature,
                    tooltip: true,
                    withText: true,
                });

                button.on("execute", () => {
                    const dialog = editor.plugins.get("Dialog");

                    // If the button is turned on, hide the modal.
                    if (button.isOn) {
                        this.closeDialog(dialog);

                        return;
                    }

                    button.isOn = true;

                    const defaultValue =
                        this.SIGNATURE_ROLE_BASE +(this.utilsService.getNumSignatures(this.editor) + 1).toString();
                    this.formView = this.createFormView(defaultValue);

                    this.listenTo(this.formView, "submit", () => {
                        const roleFieldView = this.formView.roleInputView;
                        const role = this.formView.role;
                        const id = this.formView.signatureId;

                        if (!this.formView.isValid() || !role) {
                            return;
                        }

                        if (defaultValue !== role) {
                            if (!this.formView.isValid()) {
                                return;
                            }
                        }

                        roleFieldView.errorText = "";

                        const signature: SignatureModel = {
                            id,
                            role,
                        };

                        editor.execute(SignaturePlugin.commandName, signature);
                        this.closeDialog(dialog);
                    });

                    this.listenTo(this.formView, "cancel", () => {
                        this.hideUI();
                        this.closeDialog(dialog);
                    });

                    dialog.show({
                        isModal: true,
                        content: this.formView,
                        title: $localize`:@@PluginFirmasTituloModal:Selecciona el rol del firmante`,
                        onHide() {
                            button.isOn = false;
                        },
                        id: "",
                    });
                });

                return button;
            }
        );
    }

    private closeDialog(dialog: Dialog): void {
        dialog.hide();
    }

    private createFormView(defaultValue?: string, defaultSignatureId?: string): SignatureFormView {
        const editor = this.editor;
        const validators = this.getFormValidators(editor);
        const formView = new SignatureFormView(
            validators,
            editor.locale,
            defaultValue,
            defaultSignatureId
        );

        return formView;
    }

    private configureConverterFromDataViewToModel(): void {
        const conversion = this.editor.conversion;

        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [this.SIGNATURE_CLASS],
            },
            model: (viewElement, { writer: modelWriter }) => {
                const role = viewElement.getAttribute(this.ATTRIBUTE_ROLE_IS_STORED);
                const id = viewElement.getAttribute("id");

                return modelWriter.createElement("signature", {
                    "signature-title": role,
                    id: id,
                });
            },
        });
    }

    private configureConverterFromModelToDataView(): void {
        const conversion = this.editor.conversion;

        conversion.for("dataDowncast").elementToElement({
            model: SignaturePlugin.pluginModelName,
            view: (modelItem, { writer: viewWriter }) =>
                this.utilsService.createSignatureView(
                    this.editor,
                    modelItem,
                    viewWriter
                ),
        });
    }

    private configureConverterFromModelToEditorView(): void {
        const conversion = this.editor.conversion;

        conversion.for("editingDowncast").elementToElement({
            model: SignaturePlugin.pluginModelName,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.utilsService.createSignatureView(
                    this.editor,
                    modelItem,
                    viewWriter
                );

                // Enable widget handling on a signature element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private getFormValidators(editor: Editor): Array<SignatureFormValidatorCallback> {
        const t = editor.t;

        return [
            (form) => {
                if (!form.role || !form.role!.length) {
                    return t(
                        $localize`:@@PluginFirmasValidacionRolVacioMensaje:El firmante no puede estar vacío`
                    );
                }

                if (
                    this.utilsService.hasRoleInDocument(
                        this.editor,
                        form.role,
                        form.signatureId
                    )
                ) {
                    return t(
                        $localize`:@@PluginFirmasValidacionRolenUsoMensaje:Este firmante ya está en el documento. Introduce otro.`
                    );
                }

                return t("");
            },
        ];
    }

    private enableBalloonActivators(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;
        this.showEditionInSignatureClick(viewDocument);
    }

    private showEditionInSignatureClick(viewDocument: ViewDocument): void {
        this.listenTo<ViewDocumentClickEvent>(viewDocument, "click", () => {
            const parentSignature = this.utilsService.getSelectedSignatureElement(this.editor);

            if (parentSignature) {
                // Then show panel but keep focus inside editor editable.
                this.showUI(
                    this.utilsService.getRole(parentSignature),
                    this.utilsService.getSignatureId(parentSignature)
                );
                return;
            }
        });
    }

    private areActionsVisible(): boolean {
        return !!this.formView && this.balloon.visibleView === this.formView;
    }

    private isUIInPanel(): boolean {
        return this.isFormInPanel() || this.areActionsInPanel();
    }

    private isFormInPanel(): boolean {
        return !!this.formView && this.balloon.hasView(this.formView);
    }

    private areActionsInPanel(): boolean {
        return !!this.formView && this.balloon.hasView(this.formView);
    }
    private isUIVisible(): boolean {
        const visibleView = this.balloon.visibleView;

        return (
            (!!this.formView && visibleView == this.formView) ||
            this.areActionsVisible()
        );
    }

    private hideUI(): void {
        if (!this.isUIInPanel) {
            return;
        }

        const editor = this.editor;
        this.removeBalloonObservers(editor);

        // Make sure the focus always gets back to the editable _before_ removing the focused form view.
        // Doing otherwise causes issues in some browsers. See https://github.com/ckeditor/ckeditor5-link/issues/193.
        editor.editing.view.focus();

        // Remove form first because it's on top of the stack.
        this.removeFormView();

        if (this.balloon.hasView(this.formView)) {
            this.balloon.remove(this.formView!);
        }

        this.hideFakeVisualSelection();
    }

    private removeBalloonObservers(editor: Editor): void {
        this.stopListening(editor.ui, "update");
        this.stopListening(this.balloon, "change:visibleView");
    }

    /**
     * Removes the {@link #formView} from the {@link #_balloon}.
     */
    private removeFormView(): void {
        if (!this.isFormInPanel()) {
            return;
        }

        this.formView!.roleInputView.fieldView.reset();
        this.balloon.remove(this.formView!);
        this.editor.editing.view.focus();
        this.hideFakeVisualSelection();
    }

    private hideFakeVisualSelection(): void {
        const model = this.editor.model;

        if (model.markers.has(this.VISUAL_SELECTION_MARKER_NAME)) {
            model.change((writer) => {
                writer.removeMarker(this.VISUAL_SELECTION_MARKER_NAME);
            });
        }
    }

    private getBalloonPositionData(): Partial<PositionOptions> {
        const view = this.editor.editing.view;
        const model = this.editor.model;
        const viewDocument = view.document;
        let target: PositionOptions["target"];

        if (model.markers.has(this.VISUAL_SELECTION_MARKER_NAME)) {
            // There are cases when we highlight selection using a marker (#7705, #4721).
            const markerViewElements = Array.from(
                this.editor.editing.mapper.markerNameToElements(
                    this.VISUAL_SELECTION_MARKER_NAME
                )!
            );
            const newRange = view.createRange(
                view.createPositionBefore(markerViewElements[0]),
                view.createPositionAfter(
                    markerViewElements[markerViewElements.length - 1]
                )
            );

            target = view.domConverter.viewRangeToDom(newRange);
        } else {
            // Make sure the target is calculated on demand at the last moment because a cached DOM range
            // (which is very fragile) can desynchronize with the state of the editing view if there was
            // any rendering done in the meantime. This can happen, for instance, when an inline widget
            // gets unlinked.
            target = () => {
                const targetSignature =
                    this.utilsService.getSelectedSignatureElement(this.editor);

                return targetSignature
                    ? // When selection is inside signature element, then attach panel to this element.
                      view.domConverter.mapViewToDom(targetSignature)!
                    : // Otherwise attach panel to the selection.
                      view.domConverter.viewRangeToDom(
                          viewDocument.selection.getFirstRange()!
                      );
            };
        }

        return { target };
    }

    private createViews(defaultValue?: string, defaultSignatureId?: string): void {
        this.formView = this.createFormView(defaultValue, defaultSignatureId);
        this.enableUserBalloonInteractions(this.editor, this.formView);
    }

    private enableUserBalloonInteractions( editor: Editor,formView: SignatureFormView): void {
        this.moveWithTabInBalloon(editor, formView);
        this.closeBallonOnEscKey(editor);
        this.configureCloseBallonWithOutsideClick();
    }

    private configureCloseBallonWithOutsideClick() : void {
        clickOutsideHandler({
            emitter: this.formView!,
            activator: () => this.isUIInPanel(),
            contextElements: () => [this.balloon.view.element!],
            callback: () => this.hideUI(),
        });
    }

    private moveWithTabInBalloon(editor: Editor, formView: SignatureConfigComponent): void {
        editor.keystrokes.set(
            "Tab",
            (data, cancel) => {
                if (this.areActionsVisible() &&
                    !formView!.focusTracker.isFocused) {
                    formView!.focus();
                    cancel();
                }
            },
            {
                // Use the high priority because the signature UI navigation is more important
                // than other feature's actions, e.g. list indentation.
                priority: "high",
            }
        );
    }

    private closeBallonOnEscKey(editor: Editor): void {
        editor.keystrokes.set("Esc", (data, cancel) => {
            if (this.isUIVisible()) {
                this.hideUI();
                cancel();
            }
        });
    }
}
