import { Injectable } from '@angular/core';
import { DowncastWriter, Editor, ViewContainerElement, ViewDocumentFragment, ViewElement, ViewNode } from 'ckeditor5';
import { ClauseConstants } from '../models/clause-constants';

@Injectable({
    providedIn: 'root'
})
export class ClausesEditionViewUtilsService {

    constructor() { }

    // Helper method for editor view downcast converters.
    public createClauseEditorView(modelItem: any, viewWriter: DowncastWriter): any {
        const id = modelItem.getAttribute("id");
        const container = viewWriter.createContainerElement('div', { class: [ClauseConstants.CLAUSE_CLASS_EDITION_VIEW], id });
        return container;
    }

    public getSelectedClauseElement(editor: Editor): ViewContainerElement | null {
        const view = editor.editing.view;
        const selection = view.document.selection;
        const selectedElement = selection.getSelectedElement();
        return this.findClauseElementAncestor(selectedElement);
    }

    private findClauseElementAncestor(position: ViewElement | null): ViewContainerElement | null {
        return (position?.getAncestors({ includeSelf: true })
            .find((ancestor): ancestor is ViewContainerElement =>
                this.isClauseElement(ancestor)) || null);
    }

    private isClauseElement(node: ViewNode | ViewDocumentFragment): boolean {
        return (node.is("containerElement") && !!node.hasClass(ClauseConstants.CLAUSE_CLASS_EDITION_VIEW));
    }
}
