import { ChangeDetectorRef, Component, ElementRef, ViewChild, AfterViewInit, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { EditorDocumentParts } from './models/editor-document-parts';
import {DecoupledEditor, AccessibilityHelp, Alignment, Autoformat, AutoImage, AutoLink, Autosave, BalloonToolbar, Base64UploadAdapter, Essentials,
	    FindAndReplace, FontBackgroundColor, FontColor, FontFamily, FontSize, GeneralHtmlSupport, Heading, Highlight, HorizontalLine,
	    ImageBlock, ImageCaption, ImageInline, ImageResize, ImageStyle, ImageTextAlternative, ImageToolbar, ImageUpload, Indent,
	    IndentBlock, Italic, Link, LinkImage, List, ListProperties, MediaEmbed, Minimap, PageBreak, Paragraph, PasteFromOffice,
        RemoveFormat, SelectAll, SpecialCharacters, SpecialCharactersArrows, SpecialCharactersCurrency, SpecialCharactersEssentials,
        SpecialCharactersLatin, SpecialCharactersMathematical, SpecialCharactersText, Strikethrough, Subscript, Superscript, Table,
        TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar, TextTransformation, TodoList, Underline,
        Undo, type EditorConfig, Style,  Bold} from 'ckeditor5';

import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import translations from 'src/js/contractBox/Ckeditor5/Translations/es.js';
import 'ckeditor5/ckeditor5.css';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SignaturePlugin } from './custom-plugins/signature/signature-plugin';
import { ClausesPlugin } from './custom-plugins/clauses/clauses-plugin';
import { EditorPermissionConfig } from './models/editor-permission-config';
import { EditorPluginConfig } from './models/editor-plugin-config';
import { EditorUtilsService } from './services/editor-utils.service';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { ITransformCleanHtmlService } from 'src/app/core/shared/services/transform-clean-html/transform-clean-html.service.interface';
import { IClausesNavigationService } from 'src/app/core/standard/services/clauses/clause-navigation/clauses-navigation.service.interface';
import { RadioPlugin } from './custom-plugins/radio/radio-plugin';


@Component({
    selector: 'app-wysiwyg-editor',
    templateUrl: './wysiwyg-editor.component.html'
})

export class WysiwygEditorComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() set dataEditor(value: string) {
        this.setData(value);
    }

    @Input() set resetHasUnsavedData(value) {
        if (value)
            this.isDirty = false;
    }

    @Input() set permissionEditor(value: EditorPermissionConfig) {
        this.setPermission(value);
    }

    @Input() set isReadOnly(value: boolean) {
        this.setExternalReadOnly(this.editorInstance, value);
    }

    @Input() showButtonTop: boolean;

    @Input() set goToTopNotifier(notifier: Subject<void>) {
        notifier.pipe(takeUntil(this.onDestroy$))
            .subscribe(() => this.goToTop());
    }

    @Input() set pluginConfiguration(pluginConfiguration: EditorPluginConfig) {
        this.currentPluginConfiguration = pluginConfiguration;
        this.editorUtilsService.enableDisablePlugins(this.editorInstance, pluginConfiguration);
    }

    @Output() public editorReady: EventEmitter<void> = new EventEmitter<void>();
    @Output() public changeContent: EventEmitter<EditorDocumentParts> = new EventEmitter<EditorDocumentParts>();
    @Output() public hasUnsavedChanges: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    @ViewChild('editorToolbarElement') private editorToolbar!: ElementRef<HTMLDivElement>;
    @ViewChild('editorMenuBarElement') private editorMenuBar!: ElementRef<HTMLDivElement>;
    @ViewChild('editorMinimapElement') private editorMinimap!: ElementRef<HTMLDivElement>;

    public isLayoutReady = false;
    public Editor = DecoupledEditor;
    public config: EditorConfig = {}; // CKEditor needs the DOM tree before calculating the configuration.
    public currentData = '';

    private currentPluginConfiguration = new EditorPluginConfig();

    private READ_ONLY_GENERAL_LOCK_ID = '5e934910-8ba5-44d7-ae5b-94069d43d9cf';
    private READ_ONLY_EXTERNAL_LOCK_ID = 'a57fd34d-dd1d-46a4-8095-1c0f58acaa86';

    private documentPartsForEditor: EditorDocumentParts = new EditorDocumentParts();
    private currentPermissions: EditorPermissionConfig = new EditorPermissionConfig();
    private isDirty = false;
    private editorInstance: DecoupledEditor;
    private onDestroy$ = new EventEmitter<void>();

    constructor(
        private editorUtilsService: EditorUtilsService,
        private changeDetector: ChangeDetectorRef,
        private genericDialogService: GenericDialogService,
        private cleanHtmlService: ITransformCleanHtmlService,
        private clausesNavigationService: IClausesNavigationService
    ) { }


    public ngOnInit(): void {
        this.showButtonTop = false;
    }

    public ngAfterViewInit(): void {
        this.useDefaultConfig();

        this.isLayoutReady = true;
        this.configureScroll();
        this.changeDetector.detectChanges();
        this.editorReady.emit();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();

        this.editorInstance.destroy();
    }

    public onReady(editor: DecoupledEditor): void {
        Array.from(this.editorToolbar.nativeElement.children).forEach(child => child.remove());
        Array.from(this.editorMenuBar.nativeElement.children).forEach(child => child.remove());

        if (editor.ui.view.toolbar.element) {
            this.editorToolbar.nativeElement.appendChild(editor.ui.view.toolbar.element);
        }

        if (editor.ui.view.menuBarView.element) {
            this.editorMenuBar.nativeElement.appendChild(editor.ui.view.menuBarView.element);
        }

        this.editorInstance = editor;
        this.setGeneralReadOnly(editor, !this.currentPermissions.canEdit);
        this.setGeneralReadOnly(editor, this.isReadOnly);
        this.editorUtilsService.enableDisablePlugins(editor, this.currentPluginConfiguration);

        this.editorReady.emit();
    }

    public onChangeContent({ editor }: ChangeEvent): void {
        const data = editor.getData();
        this.documentPartsForEditor.html = data;

        this.isDirty = true;
        this.hasUnsavedChanges.emit(this.isDirty);


        this.changeContent.emit(this.documentPartsForEditor);
    }

    private setData(data: string): void {
        if (!data) {
            return;
        }

        this.documentPartsForEditor.html = data;
        this.currentData = data;
        setTimeout(() => {
            this.isDirty = false;
            this.hasUnsavedChanges.emit(this.isDirty);
        }, 2000);
    }

    private setPermission(permissions: EditorPermissionConfig): void {
        if (!permissions) {
            return;
        }

        if (permissions.canEdit !== this.currentPermissions.canEdit) {
            this.setGeneralReadOnly(this.editorInstance, !permissions.canEdit);
        }

        this.currentPermissions.canEdit = permissions.canEdit;
    }

    private setGeneralReadOnly(editorInstance: DecoupledEditor, isEditorReadOnly: boolean): void {
        if (isEditorReadOnly) {
            editorInstance?.enableReadOnlyMode(this.READ_ONLY_GENERAL_LOCK_ID);
        } else {
            editorInstance?.disableReadOnlyMode(this.READ_ONLY_GENERAL_LOCK_ID);
        }
    }

    private setExternalReadOnly(editorInstance: DecoupledEditor, isReadOnly: boolean): void {
        if (isReadOnly) {
            editorInstance?.enableReadOnlyMode(this.READ_ONLY_EXTERNAL_LOCK_ID);
        } else {
            editorInstance?.disableReadOnlyMode(this.READ_ONLY_EXTERNAL_LOCK_ID);
        }
    }

    private useDefaultConfig(): void {
        this.config = {
            toolbar: {
                items: this.configureToolbar(),
                shouldNotGroupWhenFull: true
            },
            plugins: this.configurePlugins(),
            extraPlugins: this.configureCustomPlugins(),
            balloonToolbar: ['bold', 'italic', '|', 'link'],
            blockToolbar: [
                'fontSize',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                '|',
                'link',
                'insertTable',
                '|',
                'bulletedList',
                'numberedList',
                'indent',
                'outdent'
            ],
            fontFamily: {
                options: [
                    'default',
                    'Arial, Helvetica, sans-serif' + '!important',
                    'Arial Black, sans-serif' + '!important',
                    'Book Antiqua, serif' + '!important',
                    'Calibri, sans-serif' + '!important',
                    'Calibri Light, sans-serif' + '!important',
                    'Calisto MT, serif' + '!important',
                    'Cambria, serif' + '!important',
                    'Candara, sans-serif' + '!important',
                    'Century Gothic, sans-serif' + '!important',
                    'Comic Sans MS, sans-serif' + '!important',
                    'Consolas, monospace' + '!important',
                    'Constantia, serif' + '!important',
                    'Copperplate Gothic Bold, serif' + '!important',
                    'Copperplate Gothic Light, serif' + '!important',
                    'Courier New, Courier, monospace' + '!important',
                    'Fira Sans, sans-serif' + '!important',
                    'Gabriola, serif' + '!important',
                    'Georgia, serif' + '!important',
                    'Georgia Pro, serif' + '!important',
                    'Impact, sans-serif' + '!important',
                    'Lucida Console, monospace' + '!important',
                    'Lucida Sans Unicode, Lucida Grande, sans-serif' + '!important',
                    'Palatino Linotype, serif' + '!important',
                    'Rockwell Nova, serif' + '!important',
                    'Segoe Print, sans-serif' + '!important',
                    'Segoe Script, cursive' + '!important',
                    'Segoe UI, sans-serif' + '!important',
                    'Sitka Banner, serif' + '!important',
                    'Sitka Display, serif' + '!important',
                    'Sitka Heading, serif' + '!important',
                    'Sitka Small, serif' + '!important',
                    'Sitka Subheading, serif' + '!important',
                    'Sitka Text, serif' + '!important',
                    'Tahoma, Geneva, sans-serif' + '!important',
                    'Times New Roman, Times, serif' + '!important',
                    'Trebuchet MS, Helvetica, sans-serif' + '!important',
                    'Verdana, Geneva, sans-serif' + '!important',
                ],
                supportAllValues: true
            },
            fontSize: {
                options: [
                    'default',
                    { title: '10', model: '10px' },
                    { title: '12', model: '12px' },
                    { title: '14', model: '14px' },
                    { title: '16', model: '16px' },
                    { title: '18', model: '18px' },
                    { title: '20', model: '20px' }
                ],
                supportAllValues: true,

            },
            heading: {
                options: [
                    {
                        model: 'paragraph',
                        title: 'Paragraph',
                        class: 'ck-heading_paragraph'
                    },
                    {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Heading 1',
                        class: 'ck-heading_heading1'
                    },
                    {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Heading 2',
                        class: 'ck-heading_heading2'
                    },
                    {
                        model: 'heading3',
                        view: 'h3',
                        title: 'Heading 3',
                        class: 'ck-heading_heading3'
                    },
                    {
                        model: 'heading4',
                        view: 'h4',
                        title: 'Heading 4',
                        class: 'ck-heading_heading4'
                    },
                    {
                        model: 'heading5',
                        view: 'h5',
                        title: 'Heading 5',
                        class: 'ck-heading_heading5'
                    },
                    {
                        model: 'heading6',
                        view: 'h6',
                        title: 'Heading 6',
                        class: 'ck-heading_heading6'
                    }
                ]
            },
            image: {
                toolbar: [
                    'toggleImageCaption',
                    'imageTextAlternative',
                    '|',
                    'imageStyle:inline',
                    'imageStyle:wrapText',
                    'imageStyle:breakText',
                    '|',
                    'resizeImage'
                ]
            },
            language: 'es',
            initialData: '',
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
                decorators: {}
            },
            list: {
                properties: {
                    styles: true,
                    startIndex: true,
                    reversed: true
                }
            },
            menuBar: {
                isVisible: true
            },
            minimap: {
                container: this.editorMinimap.nativeElement,
                extraClasses: 'editor-container_include-minimap ck-minimap__iframe-content'
            },
            placeholder: '',
            style: {
                definitions: [
                    {
                        name: 'Article category',
                        element: 'h3',
                        classes: ['category']
                    },
                    {
                        name: 'Title',
                        element: 'h2',
                        classes: ['document-title']
                    },
                    {
                        name: 'Subtitle',
                        element: 'h3',
                        classes: ['document-subtitle']
                    },
                    {
                        name: 'Info box',
                        element: 'p',
                        classes: ['info-box']
                    },
                    {
                        name: 'Side quote',
                        element: 'blockquote',
                        classes: ['side-quote']
                    },
                    {
                        name: 'Marker',
                        element: 'span',
                        classes: ['marker']
                    },
                    {
                        name: 'Spoiler',
                        element: 'span',
                        classes: ['spoiler']
                    }
                ]
            },
            table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
            },
            translations: [translations],
            licenseKey: 'QjIvNGtGWlZxc1lBcDF3YXpHV0U2K3BOUUlYcm4yWEx2WWpZOEpqSng2YVNJQ0VDT1BWajB6ZkYtTWpBeU5ERXdNVEE9'
        };

    }

    private configureScroll(): void {
        const MARGIN_WITH_TOP = 100;
        const content = document.getElementById('top');
        Observable.fromEvent(content, 'scroll')
            .map(() => content.scrollTop)
            .subscribe(x => {
                this.showButtonTop = x >= MARGIN_WITH_TOP;
            });
    }

    private goToTop(): void {
        const element = document.getElementById('top');
        element?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    private configureToolbar(): string[] {
        let defaultConfig = [
            'undo',
            'redo',
            '|',
            'findAndReplace',
            'selectAll',
            '|',
            'heading',
            'style',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'removeFormat',
            '|',
            'specialCharacters',
            'horizontalLine',
            'pageBreak',
            'link',
            'ImageUpload',
            'mediaEmbed',
            'insertTable',
            'highlight',
            'blockQuote',
            '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            'indent',
            'outdent',
            '|',
            'accessibilityHelp',
            '|'
        ];

        defaultConfig.push(SignaturePlugin.pluginToolbarElementName);
        defaultConfig.push(ClausesPlugin.pluginToolbarElementName)
        defaultConfig.push(RadioPlugin.pluginToolbarElementName);

        return defaultConfig;
    }

    private configurePlugins(): any[] {
        const defaultPlugins = [
            AccessibilityHelp,
            Alignment,
            Autoformat,
            AutoImage,
            AutoLink,
            Autosave,
            BalloonToolbar,
            Base64UploadAdapter,
            Bold,
            Essentials,
            FindAndReplace,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            GeneralHtmlSupport,
            Heading,
            Highlight,
            HorizontalLine,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageUpload,
            ImageResize,
            ImageStyle,
            ImageTextAlternative,
            ImageToolbar,
            ImageUpload,
            Indent,
            IndentBlock,
            Italic,
            Link,
            LinkImage,
            List,
            ListProperties,
            MediaEmbed,
            Minimap,
            PageBreak,
            Paragraph,
            PasteFromOffice,
            RemoveFormat,
            SelectAll,
            SpecialCharacters,
            SpecialCharactersArrows,
            SpecialCharactersCurrency,
            SpecialCharactersEssentials,
            SpecialCharactersLatin,
            SpecialCharactersMathematical,
            SpecialCharactersText,
            Strikethrough,
            Style,
            Subscript,
            Superscript,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            TextTransformation,
            TodoList,
            Underline,
            Undo
        ];

        return defaultPlugins;
    }

    private configureCustomPlugins(): any[] {
        const extraPlugins = [];

        extraPlugins.push(SignaturePlugin);
        extraPlugins.push(ClausesPlugin);
        ClausesPlugin.genericDialog = this.genericDialogService;
        ClausesPlugin.cleanHtmlService = this.cleanHtmlService;
        ClausesPlugin.clausesNavigationService = this.clausesNavigationService;
        extraPlugins.push(RadioPlugin);
        return extraPlugins;
    }
}
