import { Injectable } from "@angular/core";
import { DecoupledEditor, DowncastWriter, Editor, ViewContainerElement,
         ViewDocumentFragment, ViewElement, ViewNode } from "ckeditor5";
import { SignatureModel } from "./models/signature-model";

@Injectable({
    providedIn: "root",
})

export class SignatureUtilsService {

    private SIGNATURE_CLASS = "signature-in-editor";
    private ATTRIBUTE_ROLE_IS_STORED = "signature-title";
    private SIGNATURE_SIGN_IN_PLACEMENT_CLASS = "signature-sign-placement";
    private SIGNATURE_SIGN_IN_ROL_CLASS = "signature-sign-rol";
    private SIGNATURE_CONTENT_CLASS = "signature-content";
    private SIGNATURE_ID_BASE = "signature_";
    private SIGNATURE_ROLE_BASE = "Firmante ";

    constructor() {}

    public getSelectedSignatureElement(editor: Editor): ViewContainerElement | null {
        const view = editor.editing.view;
        const selection = view.document.selection;
        const selectedElement = selection.getSelectedElement();
        return this.findSignatureElementAncestor(selectedElement);
    }

    public getNumSignatures(editor: Editor): number {
        const data = (editor as DecoupledEditor).sourceElement;
        return data
            ? data.querySelectorAll(`.${this.SIGNATURE_CLASS}`).length
            : 0;
    }

    public hasRoleInDocument(editor: Editor, role: string, excludeSignatureId?: string): boolean {
        const data = (editor as DecoupledEditor).sourceElement;

        let queryFindRoleDifferentFromCurrent = `[${this.ATTRIBUTE_ROLE_IS_STORED}="${role}"]:not([id="${excludeSignatureId}"])`;
        if (!excludeSignatureId) {
            queryFindRoleDifferentFromCurrent = `[${this.ATTRIBUTE_ROLE_IS_STORED}="${role}"]`;
        }

        return data?.querySelector(queryFindRoleDifferentFromCurrent) !== null;
    }

    public getRole(element: ViewContainerElement | null): string {
        const role = element?.getAttribute(this.ATTRIBUTE_ROLE_IS_STORED);
        return role ? role : "";
    }

    public getSignatureId(element: ViewContainerElement | null): string {
        const id = element?.getAttribute("id");
        return id ? id : "";
    }

    // Helper method for both downcast converters.
    public createSignatureView(editor: Editor, modelItem: any, viewWriter: DowncastWriter): any {
        const role = modelItem.getAttribute(this.ATTRIBUTE_ROLE_IS_STORED);
        const id = modelItem.getAttribute("id");

        return this.addSignatureTemplate(editor, viewWriter, role, id);
    }

    private addSignatureTemplate(editor: Editor, viewWriter: DowncastWriter, role: string, id: string): any {
        const showRole = true;
        const currentId = id ? id : "";
        const signature: SignatureModel = {
            id: currentId,
            role,
        };

        const roleAndRoleToShow = this.getSignatureRoleAndRoleToShow(
            editor,
            signature,
            showRole
        );

        const element = viewWriter.createContainerElement(
            "div",
            {
                class: this.SIGNATURE_CLASS,
                id: roleAndRoleToShow.id,
                "signature-title": roleAndRoleToShow.signatureRole,
            },
            [
                this.addSignatureTemplateContent(
                    roleAndRoleToShow.signatureRole,
                    roleAndRoleToShow.roleToShow,
                    viewWriter
                ),
            ]
        );

        return element;
    }

    private addSignatureTemplateContent(signatureRole: string, rolToShow: string, viewWriter: DowncastWriter): any {
        const signatureRoleText = "[" + signatureRole + "]";
        const tdElement = viewWriter.createContainerElement(
            "td",
            {
                class: this.SIGNATURE_SIGN_IN_PLACEMENT_CLASS,
            },
            [viewWriter.createText(signatureRoleText)]
        );

        const tdFooterElement = viewWriter.createContainerElement(
            "td",
            {
                class: this.SIGNATURE_SIGN_IN_ROL_CLASS,
            },
            [viewWriter.createText(rolToShow)]
        );

        const contentElement = viewWriter.createContainerElement(
            "div",
            {
                class: this.SIGNATURE_CONTENT_CLASS,
            },
            [
                viewWriter.createContainerElement("table", null, [
                    viewWriter.createContainerElement("tbody", null, [
                        viewWriter.createContainerElement("tr", null, [
                            tdElement,
                        ]),
                    ]),
                    viewWriter.createContainerElement("trfoot", null, [
                        tdFooterElement,
                    ]),
                ]),
            ]
        );

        return contentElement;
    }

    private getSignatureRoleAndRoleToShow(editor: Editor, signature: SignatureModel,
                                          showRol: boolean): { signatureRole: string; roleToShow: string; id: string } {
        let signatureRole = "";
        let id = "-1";
        if (!signature.id || signature.id.trim() === "") {
            id =
                this.SIGNATURE_ID_BASE +
                (this.getNumSignatures(editor) + 1).toString();
        } else {
            id = signature.id;
        }

        if (!signature.role || signature.role.trim() === "") {
            signatureRole =
                this.SIGNATURE_ROLE_BASE +
                (this.getNumSignatures(editor) + 1).toString();
        } else {
            signatureRole = signature.role;
        }

        let roleToShow = "";
        if (showRol) {
            roleToShow = signatureRole;
        }

        return { signatureRole, roleToShow, id };
    }

    private findSignatureElementAncestor(position: ViewElement | null): ViewContainerElement | null {
        return (position?.getAncestors({ includeSelf: true })
                .find((ancestor): ancestor is ViewContainerElement =>
                      this.isSignatureElement(ancestor)) || null);
    }

    private isSignatureElement(node: ViewNode | ViewDocumentFragment): boolean {
        return (node.is("containerElement") && !!node.hasClass(this.SIGNATURE_CLASS));
    }
}
