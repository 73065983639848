import { Injectable } from "@angular/core";
import { ButtonView, DecoupledEditor, View, } from "ckeditor5";
import { SignaturePlugin } from "../custom-plugins/signature/signature-plugin";
import { EditorPluginConfig } from "../models/editor-plugin-config";

@Injectable({
    providedIn: "root",
})
export class EditorUtilsService {

    private DISABLE_PLUGIN_LOCK = 'fb445a1b-6bb9-47d0-9249-8ba5b8e3419c';

    constructor() {}

    public enableDisablePlugins(editorInstance: DecoupledEditor, configuration: EditorPluginConfig) {
        this.enableDisableSignaturePlugins(editorInstance, configuration);
    }

    public showToolbarButton(editorInstance: DecoupledEditor,label: string) {
        const button = this.getToolbarButton(editorInstance, label);
        if (!button) {
            return;
        }

        button.isVisible = true;
    }

    public hideToolbarButton(editorInstance: DecoupledEditor,label: string) {
        const button = this.getToolbarButton(editorInstance, label);
        if (!button) {
            return;
        }

        button.isVisible = false;
    }

    private enableDisableSignaturePlugins(editorInstance: DecoupledEditor,  configuration: EditorPluginConfig) {
        const signature = editorInstance?.plugins.get(
            SignaturePlugin.pluginName
        );
        const signaturePlugin = signature as SignaturePlugin;

        if (!signaturePlugin) {
            return;
        }

        if (configuration?.showSignature) {
            signaturePlugin?.clearForceDisabled(this.DISABLE_PLUGIN_LOCK);
            this.showToolbarButton(editorInstance, SignaturePlugin.toolbarButtonName);
        } else {
            signaturePlugin.forceDisabled(this.DISABLE_PLUGIN_LOCK);
            this.hideToolbarButton(editorInstance, SignaturePlugin.toolbarButtonName);
        }
    }

    private finderButtonByLabel = function (
        view: View,
        label: string
    ): boolean {
        const button = view as ButtonView;
        if (!button) {
            return false;
        }

        return button.label === label;
    };

    private getToolbarButton(editorInstance: DecoupledEditor, label: string): ButtonView {
        const toolbarElement = editorInstance.ui.view.toolbar.items.find(
            (a: View<HTMLElement>, b: number) =>
                this.finderButtonByLabel(a, label)
        );
        if (!toolbarElement) {
            return null;
        }
        return toolbarElement as ButtonView;
    }
}
