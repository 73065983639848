
import { Command, Writer } from 'ckeditor5';
import { RadioInputConstants } from '../models/radio-input-constants';

export default class AddRadioCommand extends Command {

    private readonly helpTextOptionMessage = $localize`:@@TextoAyudaOpcionRadioPlugin:Texto ayuda Opción`;
    private readonly contentOptionMessage = $localize`:@@ContenidoOpcionRadioPlugin:Contenido Opción`;

    public override execute(value: number) {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {

            const guid = this.generarUUID();

            const radio = writer.createElement(RadioInputConstants.CONTAINER_LABEL_DATA_MODEL, {
                'optionsCount': value.toString(),
                id: `ck-radio-id-${guid}`,
            });

            for (let i = 0; i < value; i++) {
                const option = writer.createElement(RadioInputConstants.OPTION_LABEL_DATA_MODEL, {
                    'position': i.toString()
                });

                const check = writer.createElement(RadioInputConstants.INPUT_LABEL_DATA_MODEL, {
                    'dataName': `ck-radio-id-${guid}`
                });

                const description = writer.createElement(RadioInputConstants.DESCRIPTION_LABEL_DATA_MODEL, {
                });

                const content = writer.createElement(RadioInputConstants.CONTENT_LABEL_DATA_MODEL, {

                });

                const descriptionDefaultText = writer.createText(`(${this.helpTextOptionMessage} ${i + 1})`);
                const descriptionDefaultTextElement = writer.createElement('paragraph');
                const contentDefaultTextElement = writer.createElement('paragraph');
                const contentDefaultText = writer.createText(`${this.contentOptionMessage} ${i + 1}`);

                writer.append(descriptionDefaultText, descriptionDefaultTextElement);
                writer.append(descriptionDefaultTextElement, description);
                writer.append(contentDefaultText, contentDefaultTextElement);
                writer.append(contentDefaultTextElement, content);

                writer.append(check, option);
                writer.append(description, option);
                writer.append(content, option);
                writer.append(option, radio);
            }

            editor.model.insertObject(radio, null, null, { setSelection: 'after' });
        });
    }

    public override refresh(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position: any = selection.getFirstPosition();
        if (!position) {
            return;
        }
        const allowedIn = model.schema.findAllowedParent(position, RadioInputConstants.CONTAINER_LABEL_DATA_MODEL);
        this.isEnabled = allowedIn !== null;
    }

    private generarUUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
