export class ClauseConstants {

    public static readonly CLAUSE_LABEL_MODEL = "embedded-clause";
    public static readonly CLAUSE_CLASS_DATA_VIEW = "clause-in-editor";
    public static readonly CLAUSE_CLASS_EDITION_VIEW = "clause-in-editor";

    public static readonly CLAUSE_IN_EDITOR_CLASS = "clause-in-editor";

    public static readonly CLAUSE_PLUGIN_NAME = "Clauses";
    public static readonly TOOLBAR_NAME_STRING = "Cláusula";

    public static readonly COMMAND_NAME_INSERT_CLAUSE = "embedded-clause";
    public static readonly TOOLBAR_BUTTON_NAME_CLAUSES = "embedded-clause";
    public static readonly TOOLBAR_TOOLTIP = "Insertar cláusula de la Biblioteca";
    public static readonly CLAUSE_EDITOR_ICON = '<svg xmlns="http://www.w3.org/2000/svg" id="square-list" viewBox="0 0 448 512"> <path d="M128 184C114.7 184 104 173.3 104 160C104 146.7 114.7 136 128 136C141.3 136 152 146.7 152 160C152 173.3 141.3 184 128 184zM192 160C192 151.2 199.2 144 208 144H336C344.8 144 352 151.2 352 160C352 168.8 344.8 176 336 176H208C199.2 176 192 168.8 192 160zM192 256C192 247.2 199.2 240 208 240H336C344.8 240 352 247.2 352 256C352 264.8 344.8 272 336 272H208C199.2 272 192 264.8 192 256zM192 352C192 343.2 199.2 336 208 336H336C344.8 336 352 343.2 352 352C352 360.8 344.8 368 336 368H208C199.2 368 192 360.8 192 352zM128 232C141.3 232 152 242.7 152 256C152 269.3 141.3 280 128 280C114.7 280 104 269.3 104 256C104 242.7 114.7 232 128 232zM128 376C114.7 376 104 365.3 104 352C104 338.7 114.7 328 128 328C141.3 328 152 338.7 152 352C152 365.3 141.3 376 128 376zM0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96zM32 96V416C32 433.7 46.33 448 64 448H384C401.7 448 416 433.7 416 416V96C416 78.33 401.7 64 384 64H64C46.33 64 32 78.33 32 96z"></path> </svg>';

    public static readonly COMMAND_NAME_DELETE_CLAUSE = "delete-clause";
    public static readonly DELETE_OPTION = 'BORRAR';
    public static readonly BALLOON_ICON_DELETE_CLAUSE = '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="24px" viewBox="0 0 24 24"><path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"></path></svg>';

    public static readonly COMMAND_NAME_GO_TO_CLAUSE = "goto-clause";
    public static readonly GO_TO_CLAUSE = 'IR A CLAUSULA';
    public static readonly BALLOON_ICON_GO_TO_CLAUSE = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M173 131.4C222.1 81.52 303.9 81.52 353.8 131.4C401.3 178.9 403.1 254.9 360 305.6L354.7 311.7C348.9 318.4 338.8 319.1 332.1 313.3C325.5 307.6 324.7 297.5 330.5 290.8L335.9 284.6C368.8 246.6 366.8 189.6 331.2 154.1C293.8 116.6 233.1 116.6 195.7 154.1L71.1 278.6C33.61 316.1 33.61 376.9 71.1 414.4C108.6 451.9 169.4 451.9 206.9 414.4L229.5 391.8C235.7 385.5 245.9 385.5 252.1 391.8C258.4 398 258.4 408.1 252.1 414.4L229.5 437C179.5 487 98.46 487 48.47 437C-1.516 387-1.516 305.1 48.47 256L173 131.4zM466.1 380.6C417 430.5 336.1 430.5 286.2 380.6C238.7 333.1 236 257.1 279.1 206.4L285.3 200.3C291.1 193.6 301.2 192.9 307.9 198.7C314.5 204.4 315.3 214.5 309.5 221.2L304.1 227.4C271.2 265.4 273.2 322.4 308.8 357.9C346.2 395.4 406.9 395.4 444.3 357.9L568.9 233.4C606.4 195.9 606.4 135.1 568.9 97.61C531.4 60.12 470.6 60.12 433.1 97.61L410.5 120.2C404.3 126.5 394.1 126.5 387.9 120.2C381.6 113.1 381.6 103.9 387.9 97.61L410.5 74.98C460.5 24.99 541.5 24.99 591.5 74.98C641.5 124.1 641.5 206 591.5 255.1L466.1 380.6z"/></svg>';

}
