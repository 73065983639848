import { Injectable } from "@angular/core";
import { ContextualBalloon, Editor } from "ckeditor5";
import type { PositionOptions } from 'ckeditor5/src/utils.js';
import ClauseBalloonFormView from "../ui/clause-balloon-view.directive";
import { ClausesEditionViewUtilsService } from "./clauses-edition-view-utils.service";


@Injectable({
  providedIn: 'root'
})
export class ClausesBalloonUtilService {

  private VISUAL_SELECTION_MARKER_NAME = 'embedded-clause';

  private clausesViesService: ClausesEditionViewUtilsService;

  constructor() {
    this.clausesViesService = new ClausesEditionViewUtilsService();
   }

  public getBalloonPositionData(editor: Editor): Partial<PositionOptions> {
    const view = editor.editing.view;
    const model = editor.model;
    const viewDocument = view.document;
    let target: PositionOptions[ 'target' ];

    if ( model.markers.has( this.VISUAL_SELECTION_MARKER_NAME )) {
      const markerViewElements = Array.from(  editor.editing.mapper.markerNameToElements( this.VISUAL_SELECTION_MARKER_NAME )!);
      const newRange = view.createRange(
        view.createPositionBefore( markerViewElements[ 0 ] ),
        view.createPositionAfter(markerViewElements [markerViewElements.length -1])
      );
      target = view.domConverter.viewRangeToDom( newRange );
    } else {
      target = () => {
        const targetClause = this.clausesViesService.getSelectedClauseElement(editor);

				return targetClause ?
					view.domConverter.mapViewToDom( targetClause )! :
          view.domConverter.viewRangeToDom( viewDocument.selection.getFirstRange()!);
      };
    }

    return { target };
  }

  public removeBalloonView(editor: Editor, balloon: ContextualBalloon, balloonView: ClauseBalloonFormView): void {
    if (!this.isBalloonInPanel(balloon, balloonView)) {
        return;
    }

    balloon.remove(balloonView!);
    editor.editing.view.focus();
    this.hideFakeVisualSelection(editor);
  }

  public hideFakeVisualSelection(editor: Editor): void {
    const model = editor.model;

    if (model.markers.has(this.VISUAL_SELECTION_MARKER_NAME)) {
        model.change((writer) => {
            writer.removeMarker(this.VISUAL_SELECTION_MARKER_NAME);
        });
    }
  }

  public isBalloonInPanel(balloon: ContextualBalloon, balloonView: ClauseBalloonFormView): boolean {
    return !!balloonView && balloon.hasView(balloonView);
  }

}
