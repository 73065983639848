import { Directive, EventEmitter, OnDestroy } from "@angular/core";
import { ButtonView, ContextualBalloon, Dialog, DowncastWriter, Editor, Plugin, ViewDocumentClickEvent, ViewElement, Widget, clickOutsideHandler, toWidget, toWidgetEditable, viewToModelPositionOutsideModelElement, } from "ckeditor5";
import { RadioEditionViewUtilsService } from "./utils/radio-edition-view-utils.service";
import { RadioDataViewUtilsService } from "./utils/radio-data-view-utils.service";
import { RadioUtilsService } from './utils/radio-utils.service';
import AddRadioCommand from './commands/add-radio-command';
import DeleteRadioCommand from './commands/delete-radio-command';
import { AddRadioFormValidatorCallback } from "./ui/add-radio-dialog-form-view.directive";
import AddRadioDialogFormView from "./ui/add-radio-dialog-form-view.directive";
import EditRadioBalloonFormView, { EditRadioFormValidatorCallback } from "./ui/edit-radio-balloon-form-view.directive";
import { RadioModel } from "./models/radio-model";
import AddOptionsCommand from "./commands/add-options-command";
import RemoveOptionsCommand from "./commands/remove-options-command";
import { RadioInputConstants } from "./models/radio-input-constants";
import DoubleClickObserver, { ViewDocumentDoubleClickEvent } from "../../utils/double-click-observer";
import { PluginUtilsService } from "../../utils/plugin-utils.service";
import RadioBalloonFormView from "./ui/radio-balloon-form-view.directive";

@Directive({
    selector: 'radio-plugin',
})
export class RadioPlugin extends Plugin implements OnDestroy {

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    private onDestroy$ = new EventEmitter<void>();
    private editorUtilsService: RadioEditionViewUtilsService;
    private dataUtilsService: RadioDataViewUtilsService;
    private utilsService: RadioUtilsService;
    private pluginUtils: PluginUtilsService;
    private balloon!: ContextualBalloon;

    private addRadioDialogFormView: AddRadioDialogFormView;
    private radioBalloonFormView: RadioBalloonFormView;
    private editRadioBalloonFormView: EditRadioBalloonFormView;

    private readonly insertOptionsMessage = $localize`:@@InsertarGrupoOpcionesRadioPlugin:Insertar grupo de opciones`;
    private readonly optionsGroupMessage = $localize`:@@GrupoOpcionesRadioPlugin:Grupo de opciones`;
    private readonly minOptionsMessage = $localize`:@@MinimasOpcionesRadioPlugin:Debe tener al menos 2 opciones.`;
    private readonly maxOptionsMessage = $localize`:@@MaximasOpcionesRadioPlugin:Debe tener 10 opciones como máximo.`;


    constructor(editor: Editor) {
        super(editor);

        this.editorUtilsService = new RadioEditionViewUtilsService();
        this.dataUtilsService = new RadioDataViewUtilsService();
        this.utilsService = new RadioUtilsService();
        this.pluginUtils = new PluginUtilsService();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public static get pluginName() {
        return "Radio" as const;
    }

    public static get pluginToolbarElementName() {
        return "radio-pg" as const;
    }

    public static get requires() {
        return [Widget, ContextualBalloon];
    }

    public static get toolbarButtonName() {
        return "radio-pg" as const;
    }

    public static get AddRadioCommandName() {
        return "add-radio-pg" as const;
    }

    public static get DeleteRadioCommandName() {
        return "delete-radio-pg" as const;
    }

    public static get AddOptionsRadioCommandName() {
        return "add-options-radio-pg" as const;
    }

    public static get RemoveOptionsRadioCommandName() {
        return "remove-options-radio-pg" as const;
    }

    public init(): void {
        this.defineSchema();
        this.defineConverters();
        this.defineCommands();
        this.defineMapper();
        this.defineUI();
    }

    private defineSchema(): void {
        const schema = this.editor.model.schema;

        schema.register(RadioInputConstants.CONTAINER_LABEL_DATA_MODEL, {
            inheritAllFrom: '$container',
            allowAttributes: ["id", RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT],
            allowContentOf: '$root',
            isObject: true
        });

        schema.register(RadioInputConstants.OPTION_LABEL_DATA_MODEL, {
            inheritAllFrom: '$container',
            allowAttributes: [RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTION_POSITION],
            allowContentOf: '$root',
            allowIn: [RadioInputConstants.CONTAINER_LABEL_DATA_MODEL]
        });

        schema.register(RadioInputConstants.INPUT_LABEL_DATA_MODEL, {
            inheritAllFrom: '$blockObject',
            allowAttributes: [RadioInputConstants.ATTRIBUTE_DATA_MODEL_GROUP_NAME, RadioInputConstants.ATTRIBUTE_DATA_MODEL_CHECKED],
            allowContentOf: '$root',
            allowIn: [RadioInputConstants.OPTION_LABEL_DATA_MODEL]

        });

        schema.register(RadioInputConstants.DESCRIPTION_LABEL_DATA_MODEL, {
            inheritAllFrom: '$blockObject',
            allowContentOf: '$root',
            allowIn: [RadioInputConstants.OPTION_LABEL_DATA_MODEL],
            disallowChildren: ['imageInline', 'imageBlock', 'table']
        });

        schema.register(RadioInputConstants.CONTENT_LABEL_DATA_MODEL, {
            inheritAllFrom: '$blockObject',
            allowContentOf: '$root',
        });
    }

    private defineConverters(): void {
        this.configureConverterFromDataViewToModel();
        this.configureConverterFromModelToDataView();
        this.configureConverterFromModelToEditorView();
    }

    private configureConverterFromDataViewToModel(): void {
        const conversion = this.editor.conversion;

        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [RadioInputConstants.CONTAINER_CLASS_DATA_VIEW],
            },
            model: (viewElement: ViewElement, { writer }) => {
                return this.dataUtilsService.createModelFromDataView(viewElement, writer);
            },
        });

        conversion.for('upcast').attributeToAttribute({
            view: RadioInputConstants.ATTRIBUTE_DATA_VIEW_OPTIONS_COUNT,
            model: RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT
        });

        conversion.for("upcast").elementToElement({
            view: {
                name: "input",
                classes: [RadioInputConstants.INPUT_CLASS_DATA_VIEW],
            },
            model: (viewElement: ViewElement, { writer }) => {
                return this.dataUtilsService.createRadioModelFromDataView(viewElement, writer);
            },
        });

        conversion.for( 'upcast' ).attributeToAttribute( {
            view: RadioInputConstants.ATTRIBUTE_DATA_VIEW_CHECKED,
            model: RadioInputConstants.ATTRIBUTE_DATA_MODEL_CHECKED
        } );

        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [RadioInputConstants.DESCRIPTION_CLASS_DATA_VIEW],
            },
            model: (viewElement: ViewElement, { writer }) => {
                return this.dataUtilsService.createDescriptionModelFromDataView(viewElement, writer);
            },
        });

        conversion.for("upcast").add(dispatcher => {
            // Look for every view <div> element.
            dispatcher.on('element:div', (evt, data, conversionApi) => {
                // Get all the necessary items from the conversion API object.
                const {
                    consumable,
                    writer,
                    safeInsert,
                    convertItem,
                    convertChildren,
                    updateConversionResult
                } = conversionApi;

                // Get view item from data object.
                const { viewItem } = data;

                if (!viewItem.hasClass(RadioInputConstants.OPTION_CLASS_DATA_VIEW)) {
                    return;
                }
                // Define elements consumables.
                const optionModelToConvert = { name: true, classes: RadioInputConstants.OPTION_CLASS_DATA_VIEW };
                const inputViewElement = { name: true, classes: RadioInputConstants.INPUT_CLASS_DATA_VIEW };
                const descriptionViewElement = { name: true, classes: RadioInputConstants.DESCRIPTION_CLASS_DATA_VIEW };

                // Tests if the view element can be consumed.
                if (!consumable.test(viewItem, optionModelToConvert)) {
                    // When an element is already consumed by higher priority converters, do nothing.
                    return;
                }

                // Check if there is at least two child (description is optional)
                if (viewItem.childCount < 2) {
                    return;
                }

                // Get the first child element.
                const firstChildItem = viewItem.getChild(0);

                // Check if the first element is a input.
                if (!firstChildItem.is('element', 'input')) {
                    return;
                }

                // Tests if the first child element can be consumed.
                if (!consumable.test(firstChildItem, inputViewElement)) {
                    return;
                }

                const position = this.dataUtilsService.getPositionFromOptionView(viewItem);
                const optionModelElement = writer.createElement(RadioInputConstants.OPTION_LABEL_DATA_MODEL, {
                    'position': position
                });

                // Insert element on a current cursor location.
                if (!safeInsert(optionModelElement, data.modelCursor)) {
                    return;
                }

                // Consume the main outer wrapper element.
                consumable.consume(viewItem, optionModelToConvert);

                // Handle conversion input element.
                const inputObject = convertItem(firstChildItem, optionModelElement);
                // Consume the input element.
                consumable.consume(firstChildItem, inputViewElement);
                const secondChildItem = viewItem.getChild(1);


                // Check if the second element is a <div> with class.
                let descriptionModelElement: any;
                let descriptionObject: any;
                if (secondChildItem.is('element', 'div') && secondChildItem.hasClass(RadioInputConstants.DESCRIPTION_CLASS_DATA_VIEW)) {
                    descriptionModelElement = secondChildItem;
                    if (!consumable.test(secondChildItem, descriptionViewElement)) {
                        return;
                    }
                    //Convert Description item to model and place position after first input cursor
                    descriptionObject = convertItem(secondChildItem, inputObject.modelCursor);

                    // Consume the description element.
                    consumable.consume(secondChildItem, descriptionModelElement);
                }

                const containerElement = writer.createElement(RadioInputConstants.CONTENT_LABEL_DATA_MODEL);

                //writer.append(slot, containerElement);
                writer.append(containerElement, optionModelElement);
                convertChildren(viewItem, containerElement);
                // const rangeNextChildrenInOption = new Range();

                updateConversionResult(optionModelElement, data);
            }, { priority: 'high' });
        });
    }

    private configureConverterFromModelToDataView(): void {
        const conversion = this.editor.conversion;

        //Necesario dispatcher, cada container se transforma en un <a> con clase y un hermano div con el contenido.
        conversion.for("dataDowncast").add(downcastDispatcher => {
            downcastDispatcher.on(`insert:${RadioInputConstants.CONTAINER_LABEL_DATA_MODEL}`, (evt, data, conversionApi) => {
                // Remember to check whether the change has not been consumed yet and consume it.
                if (!conversionApi.consumable.consume(data.item, 'insert')) {
                    return;
                }

                const modelItem = data.item;
                const id = modelItem.getAttribute("id");

                // Translate the position in the model to a position in the view.
                const viewPosition = conversionApi.mapper.toViewPosition(data.range.start);
                const linkRadiusDataView = conversionApi.writer.createEmptyElement('a',
                    {
                        class: [RadioInputConstants.CONTAINER_CLASS_SIBLING_LINK_DATA_VIEW],
                        id
                    },);
                const radiusDataView = this.dataUtilsService.createRadiusDataView(modelItem, conversionApi.writer);
                // const documentFragment = conversionApi.writer.createDocumentFragment([linkRadiusDataView, radiusDataView]);

                // Bind the newly created view element to the model element so positions will map accordingly in the future.
                conversionApi.mapper.bindElements(data.item, radiusDataView);

                // Add the newly created view element to the view.
                conversionApi.writer.insert(viewPosition, radiusDataView);
                conversionApi.writer.insert(viewPosition, linkRadiusDataView);
            });
        });

        conversion.for('dataDowncast').attributeToAttribute({
            model: RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT,
            view: RadioInputConstants.ATTRIBUTE_DATA_VIEW_OPTIONS_COUNT
        });

        conversion.for("dataDowncast").elementToElement({
            model: RadioInputConstants.OPTION_LABEL_DATA_MODEL,
            view: (modelItem, { writer }) => this.dataUtilsService.createOptionDataView(modelItem, writer),
        });

        conversion.for("dataDowncast").elementToElement({
            model: RadioInputConstants.INPUT_LABEL_DATA_MODEL,
            view: (modelItem, { writer }) => this.dataUtilsService.createRadiusElementDataView(modelItem, writer),
        });

        // conversion.for( 'dataDowncast' ).attributeToAttribute( {
        //     model: RadioInputConstants.ATTRIBUTE_DATA_MODEL_CHECKED,
        //     view: RadioInputConstants.ATTRIBUTE_DATA_VIEW_CHECKED
        // } );

        conversion.for("dataDowncast").elementToElement({
            model: RadioInputConstants.DESCRIPTION_LABEL_DATA_MODEL,
            view: (modelItem, { writer }) => this.dataUtilsService.createDescriptionElementDataView(writer),
        });

        conversion.for("dataDowncast").elementToStructure({
            model: RadioInputConstants.CONTENT_LABEL_DATA_MODEL,
            view: (modelItem, { writer }) => writer.createSlot() //No structure for container in dataView,
        });
    }

    private configureConverterFromModelToEditorView(): void {
        const conversion = this.editor.conversion;

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.CONTAINER_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createRadiusEditorView(
                    modelItem,
                    viewWriter
                );

                // Enable widget handling on a radio element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            },
        });

        conversion.for('editingDowncast').attributeToAttribute({
            model: RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT,
            view: RadioInputConstants.ATTRIBUTE_EDITION_VIEW_OPTIONS_COUNT
        });

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.OPTION_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createOptionEditionView(
                    modelItem,
                    viewWriter
                );

                // Enable widget handling on a radio element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            },
        });

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.INPUT_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createRadiusElementEditionView(
                    modelItem,
                    viewWriter
                );

                // Enable widget handling on a radio element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            },
        });

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.DESCRIPTION_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createDescriptionElementEditionView(
                    viewWriter
                );

                // Enable widget handling on a radio element inside the editing view.
                return toWidgetEditable(widgetElement, viewWriter);
            },
        });

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.CONTENT_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createContentElementEditionView(
                    viewWriter
                );

                // Enable widget handling on a radio element inside the editing view.
                return toWidgetEditable(widgetElement, viewWriter);
            },
        });
    }

    private defineCommands(): void {
        this.editor.commands.add(
            RadioPlugin.AddRadioCommandName,
            new AddRadioCommand(this.editor)
        );
        this.editor.commands.add(
            RadioPlugin.DeleteRadioCommandName,
            new DeleteRadioCommand(this.editor)
        );

        this.editor.commands.add(
            RadioPlugin.AddOptionsRadioCommandName,
            new AddOptionsCommand(this.editor)
        );
        this.editor.commands.add(
            RadioPlugin.RemoveOptionsRadioCommandName,
            new RemoveOptionsCommand(this.editor)
        );
    }

    private defineMapper(): void {
        const editor = this.editor;

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.CONTAINER_CLASS_EDITION_VIEW))
        );

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.OPTION_CLASS_EDITION_VIEW))
        );

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.INPUT_CLASS_EDITION_VIEW))
        );

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.DESCRIPTION_CLASS_EDITION_VIEW))
        );

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.CONTENT_CLASS_EDITION_VIEW))
        );
    }

    private defineUI(): void {
        const editor = this.editor;

        editor.editing.view.addObserver(DoubleClickObserver);
        this.disableRemoveOptionWhenDeletingContent();
        this.balloon = editor.plugins.get(ContextualBalloon);
        this.enableBalloonActivators();

        editor.ui.componentFactory.add(RadioPlugin.pluginToolbarElementName, (locale) => {
            const button = new ButtonView(locale);

            button.set({
                tooltip: this.insertOptionsMessage,
                withText: true,
                icon: RadioInputConstants.RADIO_TOOLBAR_ICON_SVG
            });

            button.on("execute", () => {
                const dialog = editor.plugins.get("Dialog");

                // If the button is turned on, hide the modal.
                if (button.isOn) {
                    this.closeDialog(dialog);

                    return;
                }

                button.isOn = true;

                this.addRadioDialogFormView = this.createAddRadioFormView();

                this.listenTo(this.addRadioDialogFormView, "submit", () => {
                    const optionsCount = this.addRadioDialogFormView.optionsCountInputView;

                    if (!this.addRadioDialogFormView.isValid()) {
                        return;
                    }

                    optionsCount.errorText = '';

                    editor.execute(RadioPlugin.AddRadioCommandName, this.addRadioDialogFormView.optionsCount);
                    this.closeDialog(dialog);
                });

                this.listenTo(this.addRadioDialogFormView, "cancel", () => {
                    this.hideUI();
                    this.closeDialog(dialog);
                });

                dialog.show({
                    isModal: true,
                    content: this.addRadioDialogFormView,
                    title: this.optionsGroupMessage,
                    onHide() {
                        button.isOn = false;
                    },
                    id: "",
                });
            });

            return button;
        });
    }

    private disableRemoveOptionWhenDeletingContent(): void {
        const viewDocument = this.editor.editing.view.document;
        const BACKSPACE_KEYCODE = 8;
        const DELETE_KEYCODE = 46;

        viewDocument.on('keydown', (event, data) => {
            const isAnyRemoveKeyPressed = data.keyCode === BACKSPACE_KEYCODE || data.keyCode === DELETE_KEYCODE;

            if (!isAnyRemoveKeyPressed) {
                return;
            }

            const selection = this.editor.model.document.selection;
            const firstPosition = selection.getFirstPosition();

            // Gets the block (paragraph)
            const blockElement = firstPosition.parent;
            // Gets the parent of that block (content or description)
            const parentBlockElement = blockElement.parent;

            // Check if the parent is description or content and if its first position
            const tryingDeleteBackspaceBegginingDescriptionOrContent = data.keyCode === BACKSPACE_KEYCODE &&
            ((parentBlockElement.name === RadioInputConstants.DESCRIPTION_CLASS_EDITION_VIEW ||
            parentBlockElement.name === RadioInputConstants.CONTENT_CLASS_EDITION_VIEW) &&
            firstPosition.isAtStart);

            const tryingRemoveKeysWhenOneOptionSelected = blockElement.name === RadioInputConstants.OPTION_LABEL_DATA_MODEL;
            console.log('Detecto que tengo que salirme ' + tryingRemoveKeysWhenOneOptionSelected);
            if (tryingDeleteBackspaceBegginingDescriptionOrContent) {

                // Prevent events from propagation
                data.preventDefault();
                data.stopPropagation();
                event.stop();
                event.return = true;
            } else if(tryingRemoveKeysWhenOneOptionSelected) {
                data.preventDefault();
                data.stopPropagation();
                event.stop();
                event.return = true;
            }
        }, {
            // Use the high priority because the radio UI keydown is more important
            // than other feature's actions, e.g. list indentation.
            priority: 'high'
        });
    }

    private enableUserBalloonInteractions(editor: Editor, formView: EditRadioBalloonFormView | AddRadioDialogFormView | RadioBalloonFormView): void {
        // Focus the form if the balloon is visible and the Tab key has been pressed.
        editor.keystrokes.set('Tab', (data, cancel) => {
            if (this.areActionsVisible() && !formView!.focusTracker.isFocused) {
                formView!.focus();
                cancel();
            }
        }, {
            // Use the high priority because the radio UI navigation is more important
            // than other feature's actions, e.g. list indentation.
            priority: 'high'
        });

        // Close the panel on the Esc key press when the editable has focus and the balloon is visible.
        editor.keystrokes.set('Esc', (data, cancel) => {
            if (this.isUIVisible()) {
                this.hideUI();
                cancel();
            }
        });

        // Close on click outside of balloon panel element.
        clickOutsideHandler({
            emitter: formView!,
            activator: () => this.isUIInPanel(),
            contextElements: () => [this.balloon.view.element!],
            callback: () => this.hideUI()
        });
    }

    private createAddRadioFormView(defaultValue?: number): AddRadioDialogFormView {
        const editor = this.editor;
        const validators = this.getAddFormValidators(editor);
        const formView = new AddRadioDialogFormView(validators, editor.locale, defaultValue);

        return formView;
    }

    private getAddFormValidators(editor: Editor): Array<AddRadioFormValidatorCallback> {
        const t = editor.t;

        return [
            form => {
                const optionCountNumber = Number(form.optionsCount);
                if (optionCountNumber < 2) {
                    return t(this.minOptionsMessage);
                }

                if (optionCountNumber > 10) {
                    return t(this.maxOptionsMessage);
                }

                return t('');
            }
        ];
    }

    private addRadioBallonFormView(radioId?: string, optionsCount?: number): void {
        if (!this.radioBalloonFormView) {
            this.createRadioBalloonView(radioId, optionsCount);
        }

        if (this.isRadioBalloonInPanel()) {
            const isSameRadiusEditing = this.radioBalloonFormView.id !== radioId;
            if (isSameRadiusEditing) {
                this.radioBalloonFormView.id = radioId!;
                this.radioBalloonFormView.totalOptions = optionsCount!;
            }
            return;
        }

        this.radioBalloonFormView.id = radioId!;
        this.radioBalloonFormView.totalOptions = optionsCount!;

        this.balloon.add({
            view: this.radioBalloonFormView!,
            position: this.getBalloonPositionData()
        });
    }

    private createRadioBalloonView(radioId?: string, optionsCount?: number): void {
        const editor = this.editor;
        this.radioBalloonFormView = new RadioBalloonFormView(editor.locale, radioId, optionsCount);

        this.radioBalloonFormView.editOptionsButtonView.on('execute', () => {
            this.showEditRadioBalloon(this.radioBalloonFormView.id, this.radioBalloonFormView.totalOptions);
        });

        this.radioBalloonFormView.deleteButtonView.on('execute', () => {
            editor.execute(RadioPlugin.DeleteRadioCommandName, this.radioBalloonFormView.id, this.radioBalloonFormView.totalOptions);
            this.hideUI();
        });

        // Attach lifecycle actions to the the balloon.
        this.enableUserBalloonInteractions(this.editor, this.radioBalloonFormView);
    }

    private addEditRadioBalloonFormView(radioId?: string, optionsCount?: number): void {
        if (!this.editRadioBalloonFormView) {
            this.createEditRadioBalloonView(radioId, optionsCount);
        }

        if (this.isEditRadioBalloonInPanel()) {
            const isSameRadiusEditing = this.editRadioBalloonFormView.id !== radioId;
            if (isSameRadiusEditing) {
                this.editRadioBalloonFormView!.resetFormStatus();
                this.editRadioBalloonFormView.id = radioId!;
                this.editRadioBalloonFormView.optionsCount = optionsCount!;
            }
            return;
        }

        this.editRadioBalloonFormView!.resetFormStatus();
        this.editRadioBalloonFormView.id = radioId!;
        this.editRadioBalloonFormView.optionsCount = optionsCount!;

        const dialog = this.editor.plugins.get("Dialog");

        dialog.show({
            isModal: true,
            content: this.editRadioBalloonFormView,
            title: this.optionsGroupMessage,
            onHide() {

            },
            id: "",
        });
    }

    private createEditRadioBalloonView(radioId?: string, optionsCount?: number): void {
        this.editRadioBalloonFormView = this.getEditRadioBalloonFormView(radioId, optionsCount);

        // Attach lifecycle actions to the the balloon.
        this.enableUserBalloonInteractions(this.editor, this.editRadioBalloonFormView);
    }

    private getEditRadioBalloonFormView(radioId?: string, optionsCount?: number): EditRadioBalloonFormView {
        const editor = this.editor;
        const validators = this.getEditRadioBalloonFormValidators(editor);
        const formView = new EditRadioBalloonFormView(validators, editor.locale, radioId, optionsCount);

        this.listenTo(formView, "cancel", () => {
            const dialog = editor.plugins.get("Dialog");
            this.hideUI();
            this.closeDialog(dialog);
        });

        this.listenTo(formView, "submit", () => {
            const dialog = editor.plugins.get("Dialog");
            const optionCountFieldView = formView.optionsCountInputView;
            const optionCountInForm = formView.optionsCount;
            const initialOptionsCount = formView.initialOptionsCount;
            const id = formView.id;

            if (optionCountInForm !== 0 && (!formView.isValid() || !optionCountInForm)) {
                return;
            }

            if (initialOptionsCount === optionCountInForm) {
                this.hideUI();
                this.closeDialog(dialog);
                return;
            }

            optionCountFieldView.errorText = '';

            const radioModel: RadioModel = {
                id,
                optionsCount: initialOptionsCount!,
            };

            if (initialOptionsCount! > optionCountInForm) {
                this.editor.execute(RadioPlugin.RemoveOptionsRadioCommandName, radioModel, optionCountInForm);
            } else {
                this.editor.execute(RadioPlugin.AddOptionsRadioCommandName, radioModel, optionCountInForm);
            }

            this.hideUI();
            this.closeDialog(dialog);
        });

        return formView;
    }

    private getEditRadioBalloonFormValidators(editor: Editor): Array<EditRadioFormValidatorCallback> {
        const t = editor.t;

        return [
            form => {
                const optionCountNumber = Number(form.optionsCount);
                if (optionCountNumber == 1) {
                    return t(this.minOptionsMessage);
                }

                if (optionCountNumber > 10) {
                    return t(this.maxOptionsMessage);
                }

                return t('');
            }
        ];
    }

    private areActionsVisible(): boolean {
        const visibleView = this.balloon.visibleView;

        return (!!this.editRadioBalloonFormView && visibleView === this.editRadioBalloonFormView) ||
            (!!this.radioBalloonFormView && visibleView === this.radioBalloonFormView);
    }

    private isUIVisible(): boolean {
        const visibleView = this.balloon.visibleView;

        return (!!this.editRadioBalloonFormView && visibleView === this.editRadioBalloonFormView) ||
            (!!this.radioBalloonFormView && visibleView === this.radioBalloonFormView) ||
            this.areActionsVisible();
    }

    private isUIInPanel(): boolean {
        return this.isEditRadioBalloonInPanel() || this.isAddRadioFormInPanel() || this.areActionsInPanel() || this.isRadioBalloonInPanel();
    }

    private isAddRadioFormInPanel(): boolean {
        return !!this.addRadioDialogFormView && this.balloon.hasView(this.addRadioDialogFormView);
    }

    private isRadioBalloonInPanel(): boolean {
        return !!this.radioBalloonFormView && this.balloon.hasView(this.radioBalloonFormView);
    }

    private isEditRadioBalloonInPanel(): boolean {
        return !!this.editRadioBalloonFormView && this.balloon.hasView(this.editRadioBalloonFormView);
    }

    private areActionsInPanel(): boolean {
        const visibleView = this.balloon.visibleView;

        return !!this.editRadioBalloonFormView && visibleView == this.editRadioBalloonFormView || this.areActionsVisible();
    }

    private enableBalloonActivators(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', () => {
            const parentRadius = this.utilsService.getSelectedRadiusElement(this.editor);

            if (!parentRadius) {
                return;
            }

            const idRadius = this.utilsService.getId(parentRadius);
            const currentOptionCount = parseInt(parentRadius?.getAttribute(RadioInputConstants.ATTRIBUTE_EDITION_VIEW_OPTIONS_COUNT)!);
            // Then show panel but keep focus inside editor editable.

            this.showRadioBalloon(idRadius, currentOptionCount);
        });

        this.listenTo<ViewDocumentDoubleClickEvent>(viewDocument, 'dblclick', (evt, data) => {
            const parentRadius = this.utilsService.getSelectedRadiusElement(this.editor);

            if (!parentRadius) {
                return;
            }

            const idRadius = this.utilsService.getId(parentRadius);
            const currentOptionCount = parseInt(parentRadius?.getAttribute(RadioInputConstants.ATTRIBUTE_EDITION_VIEW_OPTIONS_COUNT)!);
            // Then show panel but keep focus inside editor editable.

            this.showEditRadioBalloon(idRadius, currentOptionCount);
            data.preventDefault();
            data.stopPropagation();
            evt.stop();
            evt.return = true;
        });

        this.listenTo(viewDocument, 'change', (evt, data) => {

            const model = editor.model;
            const selectedElement = model.document.selection.getSelectedElement();

            if(!selectedElement || selectedElement.name !== RadioInputConstants.INPUT_LABEL_DATA_MODEL) {
                return;
            }

            const editingView = this.editor.editing.view;

            const selectedElementEditView = this.pluginUtils.getSelectedElementWithClass(editor, RadioInputConstants.INPUT_CLASS_EDITION_VIEW);

            editingView.change((writer: DowncastWriter) => {
                const isChecked = selectedElementEditView.getAttribute(RadioInputConstants.ATTRIBUTE_EDITION_VIEW_CHECKED);

                if(isChecked) {
                    return;
                }

                writer.setAttribute('checked', true, selectedElementEditView);

                const parentRadio = selectedElementEditView.parent?.parent;

                if(parentRadio) {
                    const allInputTypeRadio = Array.from(parentRadio.getChildren());

                    allInputTypeRadio.forEach(child => {
                        if (child.is('view:element')) {
                            if(child.is('view:element') && (child as ViewElement).hasClass(RadioInputConstants.OPTION_CLASS_EDITION_VIEW))
                            {
                                const radio = (child as ViewElement).getChild(0);

                                if(radio && radio !== selectedElementEditView){
                                    writer.removeAttribute(RadioInputConstants.ATTRIBUTE_EDITION_VIEW_CHECKED, radio as ViewElement);
                                }
                            }
                        }
                    })
                }

            });

            model.change(writer => {
                writer.setAttribute('checked', true, selectedElement);
                const parentRadio = selectedElement.parent?.parent;

                if(parentRadio) {
                    const allInputTypeRadio = Array.from(parentRadio.getChildren());

                    allInputTypeRadio.forEach(child => {
                        if (child.is('element') && child.name === RadioInputConstants.OPTION_LABEL_DATA_MODEL){
                            const radio = child.getChild(0);

                            if(radio && radio !== selectedElement){
                                writer.setAttribute('checked', false, radio);
                            }
                        }
                    })
                }
            });

        }, {priority: "high"});
    }

    private showRadioBalloon(currentId?: string, currentOptionsCount?: number, forceVisible: boolean = false): void {
        if (!this.radioBalloonFormView) {
            this.addRadioBallonFormView(currentId, currentOptionsCount);
        }

        const radiusElement = this.utilsService.getSelectedRadiusElement(this.editor);
        // When there's no radio under the selection, go straight to the editing UI.
        if (!radiusElement) {
            // Show visual selection on a text without a link when the contextual balloon is displayed.
            // See https://github.com/ckeditor/ckeditor5/issues/4721.
            this.pluginUtils.showFakeVisualSelection(this.editor, RadioInputConstants.VISUAL_SELECTION_MARKER_NAME);

            this.addRadioBallonFormView(currentId, currentOptionsCount);
        } else {
            this.addRadioBallonFormView(currentId, currentOptionsCount);
        }

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        // Begin responding to ui#update once the UI is added.
        this.startUpdatingUI();
    }

    private showEditRadioBalloon(currentId?: string, currentOptionsCount?: number, forceVisible: boolean = false): void {
        this.removeRadioBalloonFormView();

        if (!this.editRadioBalloonFormView) {
            this.addEditRadioBalloonFormView(currentId, currentOptionsCount);
        }

        const radiusElement = this.utilsService.getSelectedRadiusElement(this.editor);
        // When there's no radio under the selection, go straight to the editing UI.
        if (!radiusElement) {
            // Show visual selection on a text without a link when the contextual balloon is displayed.
            // See https://github.com/ckeditor/ckeditor5/issues/4721.
            this.pluginUtils.showFakeVisualSelection(this.editor, RadioInputConstants.VISUAL_SELECTION_MARKER_NAME);

            this.addEditRadioBalloonFormView(currentId, currentOptionsCount);
        } else {
            this.addEditRadioBalloonFormView(currentId, currentOptionsCount);
        }

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        // Begin responding to ui#update once the UI is added.
        this.startUpdatingUI();
    }

    private startUpdatingUI(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        let prevSelectedRadio = this.utilsService.getSelectedRadiusElement(this.editor);
        let prevSelectionParent = getSelectionParent();

        const update = () => {
            const selectedRadio = this.utilsService.getSelectedRadiusElement(this.editor);
            const selectionParent = getSelectionParent();

            // Hide the panel if:
            //
            // * the selection went out of the EXISTING radio element. E.g. user moved the caret out
            //   of the link,
            // * the selection went to a different parent when creating a NEW radio. E.g. someone
            //   else modified the document.
            // * the selection has expanded (e.g. displaying radio actions then pressing SHIFT+Right arrow).
            //
            // Note: #_getSelectedLinkElement will return a link for a non-collapsed selection only
            // when fully selected.
            if ((prevSelectedRadio && !selectedRadio) ||
                (!prevSelectedRadio && selectionParent !== prevSelectionParent)) {
                this.hideUI();
            }
            // Update the position of the panel when:
            //  * Radio panel is in the visible stack
            //  * the selection remains in the original radio element,
            //  * there was no radio element in the first place, i.e. creating a new radio
            else if (this.isUIVisible()) {
                // If still in a radio element, simply update the position of the balloon.
                // If there was no radio (e.g. inserting one), the balloon must be moved
                // to the new position in the editing view (a new native DOM range).
                this.balloon.updatePosition(this.getBalloonPositionData());
            }

            prevSelectedRadio = selectedRadio;
            prevSelectionParent = selectionParent;
        };

        function getSelectionParent() {
            return viewDocument.selection.focus!.getAncestors()
                .reverse()
                .find((node): node is ViewElement => node.is('element'));
        }

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }

    private hideUI(): void {
        if (!this.isUIInPanel) {
            return;
        }

        const editor = this.editor;

        this.stopListening(editor.ui, 'update');
        this.stopListening(this.balloon, 'change:visibleView');

        // Make sure the focus always gets back to the editable _before_ removing the focused form view.
        // Doing otherwise causes issues in some browsers. See https://github.com/ckeditor/ckeditor5-link/issues/193.
        editor.editing.view.focus();

        // Remove form first because it's on top of the stack.
        this.removeAddFormView();
        this.removeRadioBalloonFormView();
        this.removeEditRadioBalloonFormView();

        this.hideFakeVisualSelection();
    }

    private removeAddFormView(): void {
        if (!this.isAddRadioFormInPanel()) {
            return;
        }
        this.addRadioDialogFormView!.resetFormStatus();

        this.balloon.remove(this.addRadioDialogFormView!);
        this.editor.editing.view.focus();

        this.hideFakeVisualSelection();
    }

    private removeRadioBalloonFormView(): void {
        if (!this.isRadioBalloonInPanel()) {
            return;
        }

        this.balloon.remove(this.radioBalloonFormView!);

        this.editor.editing.view.focus();

        this.hideFakeVisualSelection();
    }

    private removeEditRadioBalloonFormView(): void {
        if (!this.isEditRadioBalloonInPanel()) {
            return;
        }
        this.editRadioBalloonFormView!.resetFormStatus();

        this.balloon.remove(this.editRadioBalloonFormView!);

        this.editor.editing.view.focus();

        this.hideFakeVisualSelection();
    }

    private hideFakeVisualSelection(): void {
        this.pluginUtils.hideFakeVisualSelection(this.editor, RadioInputConstants.VISUAL_SELECTION_MARKER_NAME);
    }

    private getBalloonPositionData() {
        return this.pluginUtils.getBalloonPositionData(this.editor, RadioInputConstants.CONTAINER_CLASS_EDITION_VIEW, RadioInputConstants.VISUAL_SELECTION_MARKER_NAME);
    }

    private closeDialog(dialog: Dialog): void {
        dialog.hide();
    }
}
