
import { Command } from 'ckeditor5';
import { SignatureModel } from './models/signature-model';

export default class SignatureCommand extends Command {

    public override execute(value: SignatureModel): void {
        const editor = this.editor;

        editor.model.change( writer => {
            const signature = writer.createElement('signature', {
                'signature-title': value.role,
                'id': value.id
            });

            editor.model.insertObject( signature, null, null, {setSelection: 'after'} );
        } );
    }

    public override refresh(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position: any = selection.getFirstPosition()

        if (!position) {
          return;
        }

        const allowedIn = model.schema.findAllowedParent(position, 'signature');
        this.isEnabled = allowedIn !== null;
    }
}
