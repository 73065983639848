import { Command } from "ckeditor5";
import { ClauseModel } from "./models/clause-model";

export default class ClauseCommand extends Command {

    public override execute( value: ClauseModel ) {
        const editor = this.editor;

        editor.model.change( writer => {

            const clause = writer.createElement('embedded-clause', {
                'id': 'id_' + value.id,
                'content': value.content
            });

            const content = value.content;
            const viewFragment = editor.data.processor.toView(content);
            const modelFragement = editor.data.toModel(viewFragment);

            editor.model.insertObject(clause, null, null, { setSelection: 'after' });
            editor.model.insertContent(modelFragement, writer.createPositionAt(clause, 0));

        });
    }

  public override refresh(): void {
    const model = this.editor.model;
    const selection = model.document.selection;
    const position: any = selection.getFirstPosition()

    if (!position) {
      return;
    }

    const allowedIn = model.schema.findAllowedParent(position, 'embedded-clause');
    this.isEnabled = allowedIn !== null;
}

}
