import { Command, Range } from "ckeditor5";

export default class ClauseDeleteCommand extends Command {
  public override execute(  ) {
    const editor = this.editor;
    const selection = this.editor.model.document.selection;

    editor.model.change(writer => {
      const range = new Range(selection.getFirstPosition()!, selection.getFirstPosition()!.getShiftedBy(1));
      writer.remove( range );
    });
  }

}
