import { Directive, Inject, OnDestroy } from '@angular/core';
import {
    ButtonView,
    Locale,
    icons,
    View,
    ViewCollection,
    FocusCycler,
    FocusableView,
    FocusTracker,
    KeystrokeHandler
} from 'ckeditor5';

@Directive({
    selector: 'app-clause-config',
})
export default class ClauseFormView extends View implements OnDestroy {

    public readonly keystrokes = new KeystrokeHandler();

    public readonly focusTracker = new FocusTracker();

    private readonly _focusables = new ViewCollection<FocusableView>();

    private saveButtonView: ButtonView;
    private cancelButtonView: ButtonView;
    private currentId = '';

    private childViews: ViewCollection<ButtonView>;

    private readonly focusCycler: FocusCycler;

    constructor(
        @Inject(Locale) locale?: Locale,
    ) {
        super(locale);

        this.currentId = this.generateId();
        this.saveButtonView = this.createButton(
            'Insertar', icons.check, 'ck-button-save'
        );

        this.saveButtonView.type = 'submit';

        this.cancelButtonView = this.createButton(
            'Cancelar', icons.cancel, 'ck-button-cancel'
        );

        this.childViews = this.createCollection([
            this.saveButtonView,
            this.cancelButtonView
        ]);

        this.focusCycler = new FocusCycler( {
          focusables: this._focusables,
          focusTracker: this.focusTracker,
          keystrokeHandler: this.keystrokes,
          actions: {
            focusPrevious: 'shift + tab',

            focusNext: 'tab'
          }
        } );

        this.setTemplate({
            tag: 'form',
            attributes: {
                class: ['ck', 'ck-clause-form'],
                tabindex: '-1'
            },
            children: this.childViews,
        });
    }

    public ngOnDestroy(): void {
        super.destroy();
    }

    private createButton(label: string, icon: string, className: string): ButtonView {
        const button = new ButtonView();

        button.set({
            label,
            icon,
            tooltip: true,
            class: className
        });

        return button;
    }

    private generateId(currentId?: string | null | undefined) {
        return !!currentId ? currentId : `clause-${crypto.randomUUID()}`;
    }

    public get clauseId(): string {
        return this.currentId;
    }

    public set clauseId(value: string | null | undefined) {
        this.currentId = this.generateId(value);
    }

    public focus() {
      this.focusCycler.focusFirst();
  }


}
