import { Directive, Inject, OnDestroy } from "@angular/core";
import { ButtonView, FocusableView, FocusCycler, FocusTracker, KeystrokeHandler, Locale, submitHandler, View, ViewCollection } from "ckeditor5";
import { RadioInputConstants } from "../models/radio-input-constants";

@Directive({
    selector: "app-radio-balloon-form-view",
})
export default class RadioBalloonFormView extends View implements OnDestroy {

    public readonly keystrokes = new KeystrokeHandler();
    public readonly focusTracker = new FocusTracker();

    public deleteButtonView: ButtonView;
    public editOptionsButtonView: ButtonView;

    private radioId = '';
    private optionsCount = 0;

    private items: ViewCollection<ButtonView>;

    private readonly focusables = new ViewCollection<FocusableView>();
    private readonly focusCycler: FocusCycler;

    private readonly editOptionsnMessage = $localize`:@@EditarOpcionesRadioPlugin:Editar opciones`;
    private readonly deleteMessage = $localize`:@@BorrarRadioPlugin:Borrar`;

    constructor(
        @Inject(Locale) locale?: Locale,
        @Inject(String) radioId?: string,
        @Inject(Number) optionsCount?: number
    ) {
        super(locale);

        this.radioId = radioId!;
        this.optionsCount = optionsCount!;

        this.editOptionsButtonView = this.createButton(this.editOptionsnMessage, RadioInputConstants.RADIO_BALLOON_EDIT_ICON_SVG);
        this.deleteButtonView = this.createButton(this.deleteMessage, RadioInputConstants.RADIO_BALLOON_DELETE_ICON_SVG);

        this.items = this.createCollection([this.editOptionsButtonView, this.deleteButtonView]);

        this.focusCycler = new FocusCycler({
            focusables: this.focusables,
            focusTracker: this.focusTracker,
            keystrokeHandler: this.keystrokes,
            actions: {
                focusPrevious: "shift + tab",
                focusNext: "tab",
            },
        });

        this.setTemplate({
            tag: "form",
            attributes: {
                class: ["ck", "ck-radio-balloon"],
                tabindex: "-1",
            },
            children: this.items
        });
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    public override render(): void {
        super.render();

        submitHandler({
            view: this,
        });

        this.items.forEach((v) => {
            this.focusables.add(v);
            this.focusTracker.add(v.element!);
        });

        this.keystrokes.listenTo(this.element!);
    }

    public focus(): void {
        this.focusCycler.focusFirst();
    }

    public get id(): string {
        return this.radioId;
    }

    public set id(value: string) {
        this.radioId = value;
    }

    public get totalOptions(): number {
        return this.optionsCount;
    }

    public set totalOptions(value: number) {
        this.optionsCount = value;
    }

    private createButton(name: string, icon: string): ButtonView {
        const button = new ButtonView();
        button.set(
            {
                icon: icon,
                withText: true
            });
        return button;
    }
}
