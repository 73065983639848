export class RadioInputConstants {

    public static readonly CONTAINER_LABEL_DATA_MODEL = 'radio-pg';

    public static readonly CONTAINER_CLASS_SIBLING_LINK_DATA_VIEW = 'AN';
    public static readonly CONTAINER_CLASS_DATA_VIEW = 'rGroup';  //radio-pg
    public static readonly CONTAINER_CLASS_EDITION_VIEW = 'radio-pg';

    public static readonly ATTRIBUTE_EDITION_VIEW_OPTIONS_COUNT = 'data-options-count';
    public static readonly ATTRIBUTE_DATA_VIEW_OPTIONS_COUNT = 'data-options-count';
    public static readonly ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT = 'optionsCount';

    public static readonly ATTRIBUTE_EDITION_VIEW_CHECKED = 'checked';
    public static readonly ATTRIBUTE_DATA_VIEW_CHECKED = 'checked';
    public static readonly ATTRIBUTE_DATA_MODEL_CHECKED = 'checked';

    public static readonly OPTION_LABEL_DATA_MODEL = 'radio-pg-option';
    public static readonly OPTION_CLASS_EDITION_VIEW = 'rBox';
    public static readonly OPTION_CLASS_DATA_VIEW = 'rBox';

    public static readonly OPTION_SELECTED_ADDITIONAL_CLASS_DATA_VIEW = 'sel';

    public static readonly ATTRIBUTE_EDITION_VIEW_OPTION_POSITION = 'data-position';
    public static readonly ATTRIBUTE_DATA_VIEW_OPTION_POSITION = 'data-position';
    public static readonly ATTRIBUTE_DATA_MODEL_OPTION_POSITION = 'position';

    public static readonly INPUT_LABEL_DATA_MODEL = 'radio-pg-check';
    public static readonly INPUT_CLASS_EDITION_VIEW = 'radio-pg-check';
    public static readonly INPUT_CLASS_DATA_VIEW = 'irBox';//'radio-pg-check';

    public static readonly ATTRIBUTE_EDITION_VIEW_GROUP_NAME = 'name';
    public static readonly ATTRIBUTE_DATA_VIEW_GROUP_NAME = 'name';
    public static readonly ATTRIBUTE_DATA_MODEL_GROUP_NAME = 'dataName';

    public static readonly DESCRIPTION_LABEL_DATA_MODEL = 'radio-pg-option-description';
    public static readonly DESCRIPTION_CLASS_EDITION_VIEW = 'radio-pg-option-description';
    public static readonly DESCRIPTION_CLASS_DATA_VIEW = 'rhDesc'; //'radio-pg-option-description';

    public static readonly CONTENT_LABEL_DATA_MODEL = 'radio-pg-option-content';
    public static readonly CONTENT_CLASS_EDITION_VIEW = 'radio-pg-option-content';
    public static readonly CONTENT_CLASS_DATA_VIEW = 'radio-pg-option-content'; //No existe....

    public static readonly VISUAL_SELECTION_MARKER_NAME = 'radio-pg';

    public static readonly RADIO_TOOLBAR_ICON_SVG = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480.23-313.66q69.58 0 117.85-48.49 48.26-48.5 48.26-118.08t-48.49-117.85q-48.5-48.26-118.08-48.26t-117.85 48.49q-48.26 48.5-48.26 118.08t48.49 117.85q48.5 48.26 118.08 48.26Zm.14 189.58q-73.43 0-138.34-27.82-64.92-27.83-113.66-76.6-48.73-48.77-76.51-113.51-27.78-64.74-27.78-138.36 0-73.69 27.82-138.1 27.83-64.42 76.6-113.16 48.77-48.73 113.51-76.51 64.74-27.78 138.36-27.78 73.69 0 138.1 27.82 64.42 27.83 113.16 76.6 48.73 48.77 76.51 113.28 27.78 64.51 27.78 137.85 0 73.43-27.82 138.34-27.83 64.92-76.6 113.66-48.77 48.73-113.28 76.51-64.51 27.78-137.85 27.78Zm-.38-47.96q127.89 0 217.93-90.02 90.04-90.03 90.04-217.93 0-127.89-90.02-217.93-90.03-90.04-217.93-90.04-127.89 0-217.93 90.02-90.04 90.03-90.04 217.93 0 127.89 90.02 217.93 90.03 90.04 217.93 90.04ZM480-480Z"/></svg>';
    public static readonly RADIO_BALLOON_EDIT_ICON_SVG = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M495 59.6C516.9 81.47 516.9 116.9 495 138.8L182.3 451.6C170.9 462.9 156.9 471.2 141.5 475.8L20.52 511.4C14.9 513 8.827 511.5 4.687 507.3C.5466 503.2-1.002 497.1 .6506 491.5L36.23 370.5C40.76 355.1 49.09 341.1 60.44 329.7L373.2 16.97C395.1-4.897 430.5-4.897 452.4 16.97L495 59.6zM341 94.4L417.6 170.1L472.4 116.2C481.8 106.8 481.8 91.6 472.4 82.23L429.8 39.6C420.4 30.23 405.2 30.23 395.8 39.6L341 94.4zM318.4 117L83.07 352.4C75.5 359.9 69.95 369.3 66.93 379.6L39.63 472.4L132.4 445.1C142.7 442.1 152.1 436.5 159.6 428.9L394.1 193.6L318.4 117z"/></svg>';
    public static readonly RADIO_BALLOON_DELETE_ICON_SVG = '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="24px" viewBox="0 0 24 24"><path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"></path></svg>';
}
