import { Directive, Inject } from "@angular/core";
import { ButtonView, FocusableView, FocusCycler, FocusTracker, KeystrokeHandler, Locale, submitHandler, View, ViewCollection } from "ckeditor5";
import { ClauseConstants } from "../models/clause-constants";

@Directive({
  selector: "app-clause-balloon-config",
})
export default class ClauseBalloonFormView extends View {

    public readonly keystrokes = new KeystrokeHandler();
    public readonly focusTracker = new FocusTracker();

    private items: ViewCollection<ButtonView>;

    public deleteButtonView: ButtonView;
    public goToClauseButtonView: ButtonView;

    private readonly focusables = new ViewCollection<FocusableView>();
    private readonly focusCycler: FocusCycler;

    constructor(
        @Inject(Locale) locale?: Locale,
        @Inject(String) defaultId?: String,
    ) {
        super(locale);

        this.deleteButtonView = this.createButton(ClauseConstants.DELETE_OPTION, false, ClauseConstants.BALLOON_ICON_DELETE_CLAUSE);
        this.goToClauseButtonView = this.createButton(ClauseConstants.GO_TO_CLAUSE, false, ClauseConstants.BALLOON_ICON_GO_TO_CLAUSE);

        this.items = this.createCollection([ this.goToClauseButtonView, this.deleteButtonView]);

        this.focusCycler = new FocusCycler({
            focusables: this.focusables,
            focusTracker: this.focusTracker,
            keystrokeHandler: this.keystrokes,
            actions: {
                focusPrevious: "shift + tab",
                focusNext: "tab",
            },
        });

        this.setTemplate({
            tag: "form",
            attributes: {
                class: ["ck", "ck-clause-balloon"],
                tabindex: "-1",
            },
            children: this.items
        });
    }

    private createButton(name: string, haveText: boolean = true, icon: string ): ButtonView {
        const button = new ButtonView();
        button.set( { label: name, icon: icon, withText: haveText } );
        return button;
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }


    public override render(): void {
        super.render();

        submitHandler({
            view: this,
        });

        this.items.forEach((v) => {
            this.focusables.add(v);
            this.focusTracker.add(v.element!);
        });

        this.keystrokes.listenTo(this.element!);
    }

}
