import { Directive, Inject, OnDestroy } from '@angular/core';
import {
    View, LabeledFieldView, ButtonView, submitHandler, ViewCollection, FocusableView, Locale, FocusTracker, FocusCycler, KeystrokeHandler, InputNumberView, createLabeledInputNumber, LabelView
} from 'ckeditor5';
import FormRowView from '../../../utils/form-row-view.directive';

@Directive({
    selector: 'app-edit-radio-balloon-form-view',
})
export default class EditRadioBalloonFormView extends View implements OnDestroy {

    public optionsCountInputView: LabeledFieldView<InputNumberView>;
    public readonly keystrokes = new KeystrokeHandler();

    public readonly focusTracker = new FocusTracker();
    private titleRowLabel: LabelView;
    private removeButtonView: ButtonView;
    private saveButtonView: ButtonView;
    private cancelButtonView: ButtonView;
    private childViews: ViewCollection;

    private initialOptionsCountValue: number;
    private oldStoredValue = '2';

    private radioId = '';
    /**
     * A collection of views that can be focused in the form.
     */
    private readonly _focusables = new ViewCollection<FocusableView>();

    /**
     * Helps cycling over {@link #_focusables} in the form.
     */
    private readonly focusCycler: FocusCycler;

    private readonly validators: Array<EditRadioFormValidatorCallback>;

    private readonly optionsNumberMessage = $localize`:@@NumeroOptionesGrupoRadioPlugin:N.º de opciones para este grupo`;
    private readonly removeGroupMessage = $localize`:@@EliminarGrupoRadioPlugin:Eliminar grupo`;
    private readonly submitButtonMessage = $localize`:@@AceptarCambiosRadioPlugin:Aceptar`;
    private readonly cancelButtonMessage = $localize`:@@CancelarCambiosRadioPlugin:Cancelar`;
    private readonly notNullGroupsMessage = $localize`:@@NumeroGrupoNuloRadioPlugin:No puede ser nulo`;

    constructor(
        @Inject(Array<EditRadioFormValidatorCallback>) validators: Array<EditRadioFormValidatorCallback>,
        @Inject(Locale) locale?: Locale,
        @Inject(String) radioId?: string,
        @Inject(Number) optionsCount?: number
    ) {
        super(locale);

        this.radioId = radioId!;
        this.validators = validators;


        this.titleRowLabel = new LabelView(locale);

        this.optionsCountInputView = this.createOptionsCountInput(this.optionsNumberMessage, optionsCount?.toString());
        this.removeButtonView = this.createButton(this.removeGroupMessage, 'ck-rounded-corners');

        this.listenTo(this.removeButtonView, 'execute', () => {
            const { element } = this.optionsCountInputView.fieldView;

            if (!element) {
                return;
            }

            element.value = "0";
        });

        const useTextInButton = true;
        this.saveButtonView = this.createButton(this.submitButtonMessage, 'ck-button-save rb-plugin', undefined, useTextInButton);
        // Set the type to 'submit', which will trigger
        // the submit event on entire form when clicked.
        this.saveButtonView.type = 'submit';

        this.cancelButtonView = this.createButton(this.cancelButtonMessage, 'ck-button-cancel rb-plugin', undefined, useTextInButton);

        this.cancelButtonView.delegate('execute').to(this, 'cancel');

        const rowOptions = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.titleRowLabel,
                this.optionsCountInputView,
                this.removeButtonView
            ]
        });

        this.childViews = this.createCollection([
            this.titleRowLabel,
            rowOptions,
            this.saveButtonView,
            this.cancelButtonView
        ]);

        this.focusCycler = new FocusCycler({
            focusables: this._focusables,
            focusTracker: this.focusTracker,
            keystrokeHandler: this.keystrokes,
            actions: {
                // Navigate form fields backwards using the Shift + Tab keystroke.
                focusPrevious: 'shift + tab',

                // Navigate form fields forwards using the Tab key.
                focusNext: 'tab'
            }
        });

        this.setTemplate({
            tag: 'form',
            attributes: {
                class: ['ck', 'ck-radio-form'],
                tabindex: '-1'
            },
            children: this.childViews
        });
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    public get optionsCount(): number | null {
        const { element } = this.optionsCountInputView.fieldView;

        if (!element) {
            return null;
        }

        return parseInt(element.value);
    }

    public get initialOptionsCount(): number | null {
        return this.initialOptionsCountValue;
    }

    public set optionsCount(value: number | null) {
        const { element } = this.optionsCountInputView.fieldView;

        if (!element) {
            return;
        }

        element.value = value?.toString()!;
        this.initialOptionsCountValue = value!;
    }

    public get id(): string {
        return this.radioId;
    }

    public set id(value: string) {
        this.radioId = value;
    }

    public override render() {
        super.render();

        // Submit the form when the user clicked the save button
        // or pressed enter in the input.
        submitHandler({
            view: this
        });

        [this.optionsCountInputView, this.removeButtonView].forEach(view => {
            // Register the view as focusable.
            this._focusables.add(view!);

            // Register the view in the focus tracker.
            this.focusTracker.add(view.element!);
        });

        // Start listening for the keystrokes coming from #element.
        this.keystrokes.listenTo(this.element!);
    }

    public selectDefaultField() {
        this.optionsCountInputView.fieldView.select();
    }

    public isValid(): boolean {
        this.resetFormStatus();

        for (const validator of this.validators) {
            const errorText = validator(this);

            // One error per field is enough.
            if (errorText) {
                // Apply updated error.
                this.optionsCountInputView.errorText = errorText;

                return false;
            }
        }

        return true;
    }

    public resetFormStatus(): void {
        this.optionsCountInputView.errorText = null;
    }

    public focus() {
        this.focusCycler.focusFirst();
    }

    private createOptionsCountInput(label: string, defaultValue?: string): LabeledFieldView<InputNumberView> {
        const labeledInput = new LabeledFieldView(this.locale, createLabeledInputNumber);

        labeledInput.label = label;
        labeledInput.fieldView.value = defaultValue?.toString();
        labeledInput.fieldView.min = 0;
        labeledInput.fieldView.max = 10;
        this.oldStoredValue = defaultValue!;

        labeledInput.fieldView.on('input', (value: any) => {
            // UX: Make the error text disappear and disable the error indicator as the user
            // starts fixing the errors.

            if (labeledInput.fieldView.element?.value === '1') {
                if (this.oldStoredValue === '2') {
                    labeledInput.fieldView.element.value = '0';
                } else {
                    labeledInput.fieldView.element.value = '2';
                }
            }

            this.oldStoredValue = labeledInput.fieldView.element!.value;
            labeledInput.errorText = !!value?.source?.value ? '' : this.notNullGroupsMessage;
        });

        return labeledInput;
    }

    private createButton(label: string, className?: string, icon?: string, withText?: boolean): ButtonView {
        const button = new ButtonView();

        button.set({
            label,
            icon,
            tooltip: true,
            class: className,
            withText: !icon || withText
        });

        return button;
    }
}

export type EditRadioFormValidatorCallback = (form: EditRadioBalloonFormView) => string | undefined;
