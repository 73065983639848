import { Command, Writer } from 'ckeditor5';
import { RadioInputConstants } from '../models/radio-input-constants';

export default class DeleteRadioCommand extends Command {

    public override execute(radioId: string, totalOptionsInRadioGroup: number) {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            let radioModel: any;
            for (const value of range.getWalker()) {
                if (value.item.hasAttribute('id') && value.item.getAttribute('id') === radioId) {
                    radioModel = value;
                    break;
                }
            }

            let optionToMove: any;
            for (let positionToMove = totalOptionsInRadioGroup - 1; positionToMove >= 0; positionToMove--) {
                let contentToMove: any;
                for (const child of radioModel.item.getChildren()) {
                    if (child.hasAttribute(RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTION_POSITION) &&
                        child.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTION_POSITION) === positionToMove.toString()) {
                        optionToMove = child;
                        break;
                    }
                }

                for (const child of optionToMove.getChildren()) {
                    if (child.name === RadioInputConstants.CONTENT_LABEL_DATA_MODEL) {
                        contentToMove = child;
                        break;
                    }
                }

                this.moveContentModelAfterRadioComponent(writer, contentToMove, radioModel);
                this.removeContentDivAndExtractChildrenToParent(writer, contentToMove);
                writer.remove(optionToMove);
            }

            writer.remove(radioModel.item);
        });
    }

    public override refresh(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position: any = selection.getFirstPosition();
        if (!position) {
            return;
        }
        const allowedIn = model.schema.findAllowedParent(position, RadioInputConstants.CONTAINER_LABEL_DATA_MODEL);
        this.isEnabled = allowedIn !== null;
    }

    private moveContentModelAfterRadioComponent(writer: Writer, contentToMove: any, radioModel: any) {
        writer.insert(contentToMove, radioModel.item, 'after');
    }

    private removeContentDivAndExtractChildrenToParent(writer: Writer, contentToMove: any) {
        writer.unwrap(contentToMove);
    }
}
