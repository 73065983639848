import { Command } from "ckeditor5";
import { ClausesPlugin } from "./clauses-plugin";

export default class ClauseGoToCommand extends Command {
  public override execute(idClausula?: string) {

    //Aqui iria ir a la clausula con la llamada de la SPA
    if (idClausula){
      ClausesPlugin.clausesNavigationService.goToEditionClause(idClausula.split('_')[1])
    } else {
      window.location.href = 'https://www.google.com/';
    }

  }

}
