import { Component, HostBinding, Inject, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
    ChangeDocumentClauseDTO, DocumentDTO, DocumentService, DocumentClauseDTO, DocumentClauseService,
    DocumentSignatureDTO, DocumentSignatureService, ExpirationDateDTO
} from 'src/app/api';
import { IClauseDocumentRevisionService } from 'src/app/core/shared/services/clauses/clause-document-revision/clause-document-revision.service.interface';
import { HtmlViewerComponent } from 'src/app/shared/components/html-viewer/html-viewer.component';
import { DocumentStatusCodes } from 'src/app/core/standard/enums/documents/document-status.enum';
import { DocumentEditionTabs } from 'src/app/core/standard/enums/documents/document-edition-tabs.enum';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentClauseRevisionSendToFirmComponent } from '../document-clause-revision-send-to-firm/document-clause-revision-send-to-firm.component';
import { IDocumentsNavigationService } from 'src/app/core/standard/services/documents/documents-navigation/documents-navigation.service.interface';

@Component({
    selector: 'app-document-clause-revision-preview',
    templateUrl: './document-clause-revision-preview.component.html'
})
export class DocumentClauseRevisionPreviewComponent implements OnInit, OnDestroy {

    @Input() documentId: string;
    @Input() pendingClauses: number;
    @Input() isDocumentATemplate: boolean;

    @HostBinding('class') componentClass: string;

    @ViewChild('documentViewer') documentViewer: HtmlViewerComponent;

    public get documentStatusCodes(): typeof DocumentStatusCodes {
        return DocumentStatusCodes;
    }

    public isReviewDisabledSubscription = new BehaviorSubject<boolean>(true);

    public lastOpenedDialog: MatDialogRef<any>;
    public pendingDocuments: number;
    public waitForFinishDocuments: number;

    public forSignaturePreviously = false;
    public modalTitle: string;
    public modalCanCancel = true;
    public documentClause: DocumentClauseDTO;
    public totalDocuments: number;
    public indexDocument: number;
    public clauseOriginal = '';
    public clauseName = '';
    public clauseModified: string;
    public documentState: DocumentStatusCodes;

    public expirationDays: number;
    public expirationDate: Date;
    public newExpirationDate: Date;
    public lockUpdateHtml = false;

    public buttonColorAcceptChanges = 'primary';

    private addedTextInClauseId = 'id_';
    private currentStateSubscription: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
        public genericDialogService: GenericDialogService,
        private documentClauseService: DocumentClauseService,
        private clauseDocumentRevisionService: IClauseDocumentRevisionService,
        private documentService: DocumentService,
        private documentSignatureService: DocumentSignatureService,
        private documentsNavigationService: IDocumentsNavigationService) {
        this.pendingDocuments = clauseDocumentRevisionService.getNumPendingDocuments();
        this.waitForFinishDocuments = clauseDocumentRevisionService.getNumWaitForFinishedDocuments();
        this.totalDocuments = clauseDocumentRevisionService.getCurrentStateDocuments().size;
        this.indexDocument = 0;
        this.documentId = data.dataSourceComponent.id;
    }

    public ngOnInit(): void {
        this.componentClass = 'viewer-main';
        this.getPendingDocument(this.documentId);
        this.buttonColorAcceptChanges = (this.documentState === this.documentStatusCodes.ForSignature) ? 'warn' : 'primary';
    }

    public ngOnDestroy(): void {
        if (this.currentStateSubscription) {
            this.currentStateSubscription.unsubscribe();
        }
    }

    public subscribeToStateDocumentChange() {
        this.currentStateSubscription = this.clauseDocumentRevisionService.getCurrentStateDocumentsSubscription().subscribe(() => {
            this.pendingClauses = this.clauseDocumentRevisionService.getNumPendingDocuments();
            this.waitForFinishDocuments = this.clauseDocumentRevisionService.getNumWaitForFinishedDocuments();
            this.totalDocuments = this.clauseDocumentRevisionService.getCurrentStateDocuments().size;
            this.indexDocument = this.clauseDocumentRevisionService.getIndexDocumentClause(this.documentClause);
        });
    }

    public getPendingDocument(documentId: string) {
        this.documentClauseService.documentClausesSearchPendingDocumentDocumentIdGet(documentId)
            .subscribe((pendingClauses: DocumentClauseDTO[]) => {
                const stateDocument = new Map<string, string>();
                this.clauseDocumentRevisionService.UpdateStateDocument(stateDocument);
                pendingClauses.forEach(element => {
                    this.clauseDocumentRevisionService.setPending(element);
                });
                if (pendingClauses.length > 0) {
                    const FIRST = 0;
                    const firstDocumentClauseId = pendingClauses[FIRST].id;
                    this.loadDocument(firstDocumentClauseId);
                }

                this.pendingClauses = this.clauseDocumentRevisionService.getNumPendingDocuments();
            });
    }

    public close(): void {
        if (this.checkHasChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result === true) {
                    if (this.lastOpenedDialog) {
                        this.lastOpenedDialog.close();
                    } else {
                        return;
                    }
                }
            });
        } else {
            if (this.lastOpenedDialog) {
                this.lastOpenedDialog.close();
            } else {
                return;
            }
        }
    }

    public loadDocument(documentClauseId: string) {
        this.documentClauseService.documentClausesIdGet(documentClauseId).subscribe((document: DocumentClauseDTO) => {
            this.lockUpdateHtml = true;
            this.documentClause = document;
            this.clauseModified = document.clause.description;
            this.clauseOriginal = document.clause.description;
            this.clauseName = document.clause.name;
            this.documentViewer.markClause(this.documentClause.clause.id);
            this.loadDocumentState(document.document.id);
            this.indexDocument = this.clauseDocumentRevisionService.getIndexDocumentClause(document);
            this.totalDocuments = this.clauseDocumentRevisionService.getCurrentStateDocuments().size;
            setTimeout(() => {
                this.updateClauseContent();
                this.lockUpdateHtml = false;
            });

        });
    }

    public loadDocumentState(documentId: string) {
        if (this.isDocumentATemplate) {
            return;
        }

        this.documentService.documentIdGet(documentId).subscribe(document => {
            if (!document) {
                this.documentState = null;
                this.forSignaturePreviously = false;
            }

            this.documentState = document.documentStatus.code;
            this.forSignaturePreviously = document.documentSignature ? document.documentSignature.forSignaturePreviously : false;
        }, error => {
            this.documentState = null;
            this.forSignaturePreviously = false;
        });
    }

    public goToClauseInDocument() {
        const clauseElement = document.getElementById(this.addedTextInClauseId + this.documentClause.clause.id);
        clauseElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    public updateClauseContent(): void {
        this.clauseOriginal = this.documentClause !== undefined ? this.documentClause.clause.description : '';
    }

    public navegateNextDocument(): void {
        const nextClauseId = this.clauseDocumentRevisionService.getNextDocumentClauseId(this.indexDocument);
        this.loadDocument(nextClauseId);
    }

    public navegatePreviousDocument(): void {
        const previousClauseId = this.clauseDocumentRevisionService.getPreviousDocumentClauseId(this.indexDocument);
        this.loadDocument(previousClauseId);
    }

    public navegateFirstDocument(): void {
        const nextClauseId = this.clauseDocumentRevisionService.getFirstDocumentClauseId(this.indexDocument);
        this.loadDocument(nextClauseId);
    }

    public navegateLastDocument(): void {
        const nextClauseId = this.clauseDocumentRevisionService.getLastDocumentClauseId(this.indexDocument);
        this.loadDocument(nextClauseId);
    }

    public checkChanges(): boolean {
        if (!this.isPending(this.documentClause)) {
            return false;
        }
        if (this.documentClause !== undefined && this.documentClause.clause !== undefined) {
            const original = this.documentClause.clause.description;
            return this.htmlDecode(original) !== this.htmlDecode(this.clauseModified);
        }
        return false;
    }

    public htmlDecode(input: string) {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.getElementsByTagName('body')[0].innerHTML;
    }

    public acceptChanges(documentClause: DocumentClauseDTO): void {
        let message: string;
        let dialogButton: string;
        if (this.documentState && this.documentState === DocumentStatusCodes.ForSignature) {
            message = $localize`:@@ConfirmacionCambioMensajeCancelarFirma:Este documento está enviado a firma, para proceder a cambiar la cláusula se cancelará el envío a firma. ¿Estás seguro?`;
            dialogButton = $localize`:@@CancelarFirmaYAceptarCambios:Cancelar firma y aplicar cambios`;
        } else {
            message = $localize`:@@ConfirmacionCambioMensaje:Vas a realizar cambios en el documento. ¿Estás seguro?`;
            dialogButton = $localize`:@@ClausulaDocumentoAceptar:Aceptar`;
        }
        this.genericDialogService.showQuestion(message, '', '', dialogButton).afterClosed().subscribe((result) => {
            if (result === true) {
                const changeClause: ChangeDocumentClauseDTO = {
                    clauseId: documentClause.clause.id,
                    documentId: documentClause.document.id,
                    acceptChanges: true,
                    html: this.clauseModified
                };

                this.documentClauseService.documentClausesResolveChangesPut(changeClause).subscribe(() => {
                    this.clauseDocumentRevisionService.setApplied(documentClause);
                    this.pendingClauses = this.clauseDocumentRevisionService.getNumPendingDocuments();
                    this.doAfterReview();
                });
            }
        });
    }

    public rejectChanges(documentClause: DocumentClauseDTO): void {
        const message = $localize`:@@ConfirmacionRechazarMensaje:Vas a mantener la versión actual del documento. ¿Estás seguro?`;
        this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
            if (result === true) {
                const changeClause: ChangeDocumentClauseDTO = {
                    clauseId: documentClause.clause.id,
                    documentId: documentClause.document.id,
                    acceptChanges: false,
                };

                this.documentClauseService.documentClausesResolveChangesPut(changeClause).subscribe(() => {
                    this.clauseDocumentRevisionService.setKeep(documentClause);
                    this.pendingClauses = this.clauseDocumentRevisionService.getNumPendingDocuments();
                    this.doAfterReview();
                });
            }
        });
    }

    public doAfterReview(): void {
        this.loadDocument(this.documentClause.id);
    }

    public isPending(document: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isPending(document);
    }

    public isApplied(document: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isApplied(document);
    }

    public isKeep(document: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isKeep(document);
    }

    public clauseChanged(text: { cleanedHtml: string, rawHtml: string }) {
        this.clauseModified = text.cleanedHtml;
    }

    public finish() {
        if (this.forSignaturePreviously) {
            this.documentSignatureService.documentsSignatureExpirationDateDocumentIdGet(this.documentClause.document.id)
                .subscribe((expirationDate: ExpirationDateDTO) => {
                    this.expirationDays = expirationDate.days;
                    this.expirationDate = expirationDate.date;
                    this.OpenModalExpirationDate();
                });
        } else {
            this.close();
        }
    }

    public OpenModalExpirationDate() {
        if (this.isDocumentATemplate) {
            return;
        }
        const dataSource: any = {
            expirationDays: this.expirationDays,
            expirationDate: this.expirationDate,
        };

        const dialogButton = $localize`:@@ShareAgain:Volver a enviar a firma`;
        this.genericDialogService.showQuestion('', DocumentClauseRevisionSendToFirmComponent, dataSource, dialogButton).afterClosed()
            .subscribe((result) => {
                if (result === true) {
                    const nuevaFecha = new Date();
                    this.theNewExpirationDate(nuevaFecha);
                    const documentSignature: DocumentSignatureDTO = {
                        documentId: this.documentClause.document.id,
                        expiredDate: this.newExpirationDate
                    };
                    this.documentService.documentSendToSignSamePeoplePut(documentSignature).subscribe((document: DocumentDTO) => {
                        const message = $localize`:@@FirmaEnviadaCorrectamente:El envío a firma se ha realizado correctamente para el documento "` + document.documentSignature.name + '"';
                        this.genericDialogService.showMessage(message);
                    }, error => {
                        const message = $localize`:@@ClausulaDocumentoFirmaErrorMensaje:Se ha producido un error en el envío a firma. Puedes completar la firma desde la vista de edición del documento`;
                        this.genericDialogService.showMessage(message);
                    });
                } else {
                    this.documentService.documentCancelForSignaturePreviouslyIdPut(this.documentClause.document.id).subscribe(() => {
                        const signingTab = DocumentEditionTabs.configureSigning;
                        this.documentsNavigationService.goToDocumentEditionTab(this.documentClause.document.id,
                            DocumentEditionTabs.configureSigning);
                    }, error => {
                        const message = $localize`:@@ClausulaDocumentoErrorMensaje:Se ha producido un error`;
                        this.genericDialogService.showMessage(message);
                    });
                }
            });
    }

    public theNewExpirationDate(date: Date) {
        this.newExpirationDate = date;
    }

    public checkHasChanges(): boolean {
        if (!this.isPending(this.documentClause)) {
            return false;
        }
        return false;
    }

    public isWaitingForFinish(documentClause: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isWaitForFinish(documentClause);
    }
}

