import { Command } from 'ckeditor5';
import { RadioModel } from '../models/radio-model';
import { RadioInputConstants } from '../models/radio-input-constants';

export default class AddOptionsCommand extends Command {

    private readonly helpTextOptionMessage = $localize`:@@TextoAyudaOpcionRadioPlugin:Texto ayuda Opción`;
    private readonly contentOptionMessage = $localize`:@@ContenidoOpcionRadioPlugin:Contenido Opción`;

    public override execute(model: RadioModel, totalOptionsInRadioGroup: number) {
        const editor = this.editor;

        editor.model.change(writer => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);
            let radioOptionModel: any;
            for (const value of range.getWalker()) {
                if (value.item.hasAttribute('dataName') && value.item.getAttribute('dataName') === model.id) {
                    // A different `is` usage.
                    radioOptionModel = value;
                    break;
                }
            }

            for (let index = model.optionsCount!; index < totalOptionsInRadioGroup; index++) {

                const option = writer.createElement(RadioInputConstants.OPTION_LABEL_DATA_MODEL, {
                    'position': index.toString()
                });

                const check = writer.createElement(RadioInputConstants.INPUT_LABEL_DATA_MODEL, {
                    'dataName': model.id
                });

                const description = writer.createElement(RadioInputConstants.DESCRIPTION_LABEL_DATA_MODEL, {
                });

                const content = writer.createElement(RadioInputConstants.CONTENT_LABEL_DATA_MODEL, {

                });

                const descriptionDefaultText = writer.createText(`(${this.helpTextOptionMessage} ${index + 1})`);
                const descriptionDefaultTextElement = writer.createElement('paragraph');
                const contentDefaultTextElement = writer.createElement('paragraph');
                const contentDefaultText = writer.createText(`${this.contentOptionMessage} ${index + 1}`);

                writer.append(descriptionDefaultText, descriptionDefaultTextElement);
                writer.append(descriptionDefaultTextElement, description);
                writer.append(contentDefaultText, contentDefaultTextElement);
                writer.append(contentDefaultTextElement, content);

                writer.append(check, option);
                writer.append(description, option);
                writer.append(content, option);
                writer.append(option, radioOptionModel!.item.parent!.parent!);
            }

            let radioModel: any;
            for (const value of range.getWalker()) {
                if (value.item.hasAttribute('id') && value.item.getAttribute('id') === model.id) {
                    // A different `is` usage.
                    radioModel = value;
                    break;
                }
            }

            writer.setAttribute('optionsCount', totalOptionsInRadioGroup, radioModel.item);
        });
    }

    public override refresh(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position: any = selection.getFirstPosition();
        if (!position) {
            return;
        }
        const allowedIn = model.schema.findAllowedParent(position, RadioInputConstants.CONTAINER_LABEL_DATA_MODEL);
        this.isEnabled = allowedIn !== null;
    }

}
