import { Injectable } from '@angular/core';
import { Editor, ViewContainerElement } from 'ckeditor5';
import { RadioInputConstants } from '../models/radio-input-constants';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';

@Injectable({
    providedIn: 'root'
})
export class RadioUtilsService {

    private pluginUtils: PluginUtilsService;

    constructor() {
        this.pluginUtils = new PluginUtilsService();
    }

    public getId(radiusElement: ViewContainerElement): string {
        return radiusElement?.getAttribute('id')!;
    }

    public getSelectedRadiusElement(editor: Editor): ViewContainerElement | null {
        return this.pluginUtils.getSelectedElementWithClass(editor, RadioInputConstants.CONTAINER_CLASS_EDITION_VIEW);
    }
}
