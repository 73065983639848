import { EventEmitter, Injectable } from '@angular/core';
import { EditorDocumentParts } from '../models/editor-document-parts';
import { ICkeditorFormsUtilsService } from './ckeditor-forms-utils.service.interface';

@Injectable({
    providedIn: 'root'
})
export class CkeditorFormsUtilsService extends ICkeditorFormsUtilsService {

    constructor() {
        super();
    }

    public configureInputsAndSelectedEditables(editorInstance: any, sendDataChange: any,
                                               changeContentEmmiter: EventEmitter<EditorDocumentParts>,
                                               documentPartsForEditor: EditorDocumentParts) {
        const documentToConfigure = editorInstance.document.$;

        this.configureInputsAndSelectedForDocument(documentToConfigure, editorInstance, sendDataChange,
            changeContentEmmiter, documentPartsForEditor);
    }

    public configureInputsAndSelectedForDocumentInVisor(documentToConfigure: any) {
        const inputsList = documentToConfigure.querySelectorAll('input, textarea, select, radio, checkbox');
        for (const input of inputsList) {
            const contentEditableAttribute = input.listenerAdded;
            if (contentEditableAttribute) {
                continue;
            }

            input.listenerAdded = true;
            input.setAttribute('true', false);

            if (input.type === 'select-one') {
                input.setAttribute('contenteditable', true);
            }

            this.sortInputAttributes(input);
        }
    }

    private sortInputAttributes(element: HTMLInputElement) {
        const attributes = [];

        for (let i = 0; i < element.attributes.length; i++) {
            attributes.push({
                name: element.attributes[i].name,
                value: element.attributes[i].value
            });
        }

        const sortedAttributes = [];
        const nameAttris = ['class', 'contenteditable', 'id', 'name', 'true', 'type', 'value'];
        for (const nameAttri of nameAttris) {
            const attri = attributes.find(attr => attr.name === nameAttri);
            if (!attri) {
                continue;
            }
            sortedAttributes.push(attri);
        }

        for (const attribute of sortedAttributes) {
            element.removeAttribute(attribute.name);
        }

        for (const attribute of sortedAttributes) {
            element.setAttribute(attribute.name, attribute.value);
        }
    }

    public configureInputsAndSelectedForDocument(documentToConfigure: any, editorInstance: any, sendDataChange: any,
                                                 changeContentEmmiter: EventEmitter<EditorDocumentParts>,
                                                 documentPartsForEditor: EditorDocumentParts) {
        const inputsList = documentToConfigure.querySelectorAll('input, textarea, select, radio, checkbox');
        const context = this;
        for (const input of inputsList) {
            const contentEditableAttribute = input.listenerAdded;
            if (contentEditableAttribute) {
                continue;
            }

            input.listenerAdded = true;
            input.setAttribute('contenteditable', true);
            input.setAttribute('true', false);
            input.removeAttribute('disabled');

            input.addEventListener('mousedown', (eventMouse) => {
                eventMouse.stopPropagation();
            }, true);

            if (input.type === 'number') {
                input.addEventListener('mouseup', (eventMouse) => {
                    eventMouse.stopPropagation();
                }, true);
            }

            if (input.tagName.toLowerCase() === 'textarea') {
                input.addEventListener('change', () => {
                    this.setValueInInnerText(input);
                }, true);
            }

            if (input.tagName.toLowerCase() === 'input') {
                input.addEventListener('keydown', (event) => {
                    event.stopPropagation();
                }, true);

                input.addEventListener('input', () => {
                    const textInput = input.value;
                    input.setAttribute('value', textInput);
                }, true);
            }

            if (input.tagName.toLowerCase() === 'select') {
                this.enableSelector(input, editorInstance, sendDataChange, changeContentEmmiter, documentPartsForEditor);
            }

            if (input.tagName.toLowerCase() === 'input') {
                const type = input.getAttribute('type');
                if (type && type.toLowerCase() === 'checkbox') {
                    input.addEventListener('click', (eventInput) => {
                        const checkedAttribute = input.getAttribute('checked');
                        const checkedStatus = checkedAttribute && checkedAttribute === 'true' ? false : true;
                        input.setAttribute('checked', checkedStatus.toString());
                        eventInput.stopPropagation();

                        if (checkedStatus) {
                            input.parentElement.classList.add('sel');
                        } else {
                            input.parentElement.classList.remove('sel');
                        }
                        if (sendDataChange) {
                            var editorHeight = editorInstance.document.getBody().$.scrollHeight;
                            editorInstance.resize('100%', editorHeight);
                            sendDataChange(null, editorInstance, changeContentEmmiter, documentPartsForEditor, context);
                        }
                    }, true);
                }

                if (type && type.toLowerCase() === 'radio') {
                    input.addEventListener('click', (event) => {
                        event.stopPropagation();
                    }, true);

                    input.addEventListener('change', (event) => {
                        const isDirty = editorInstance.checkDirty();
                        const radioGroup = editorInstance.document.$.querySelectorAll('[name=\'' + event.target.name + '\']');
                        for (const radioElement of radioGroup) {
                            radioElement.removeAttribute('checked');
                            radioElement.parentElement.classList.remove('sel');
                        }

                        input.setAttribute('checked', 'true');
                        input.parentElement.classList.add('sel');

                        if (sendDataChange) {
                            var editorHeight = editorInstance.document.getBody().$.scrollHeight;
                            editorInstance.resize('100%', editorHeight);
                            sendDataChange(null, editorInstance, changeContentEmmiter, documentPartsForEditor, context);
                        }
                        if (!isDirty) {
                            editorInstance.resetDirty();
                        }
                    }, true);
                }
            }
        }
    }

    public configureInputsTextAndSelectEditablesDocument(documentToConfigure: any, editorInstance: any, sendDataChange: any,
                                                         changeContentEmmiter: EventEmitter<EditorDocumentParts>,
                                                         documentPartsForEditor: EditorDocumentParts) {
        const inputsList = documentToConfigure.querySelectorAll('input, textarea, select, radio, checkbox');

        for (const input of inputsList) {
            const contentEditableAttribute = input.listenerAdded;
            if (contentEditableAttribute) {
                continue;
            }

            if (this.isInputRadioOrCheck(input)) {
                this.setElementAsDisabled(input);
                continue;
            }

            if (this.isRadioOrCheck(input)) {
                this.setElementAsDisabled(input);
                continue;
            }

            input.listenerAdded = true;
            input.setAttribute('contenteditable', true);
            input.setAttribute('true', false);
            input.removeAttribute('disabled');

            input.addEventListener('mousedown', (eventMouse) => {
                eventMouse.stopPropagation();
            }, true);

            if (input.type === 'number') {
                input.addEventListener('mouseup', (eventMouse) => {
                    eventMouse.stopPropagation();
                }, true);
            }

            if (input.tagName.toLowerCase() === 'textarea') {
                input.addEventListener('change', () => {
                    this.setValueInInnerText(input);
                }, true);
            }

            if (input.tagName.toLowerCase() === 'input') {
                input.addEventListener('keydown', (event) => {
                    event.stopPropagation();
                }, true);

                input.addEventListener('input', () => {
                    const textInput = input.value;
                    input.setAttribute('value', textInput);
                }, true);
            }

            if (input.tagName.toLowerCase() === 'select') {
                this.enableSelector(input, editorInstance, sendDataChange, changeContentEmmiter, documentPartsForEditor);
            }
        }
    }

    public manageAnchorLinks(editorInstance: any) {
        const linkList = editorInstance.document.$.querySelectorAll('a');
        const tagsToRemove = editorInstance.document.$.querySelectorAll('a.nh');
        tagsToRemove.forEach((element) => {
            element.removeAttribute('href');
        });
        // Capturamos los tres eventos porque no queremos que salte a una nueva pestaña, si no en el mismo documento.
        for (const link of linkList) {
            const idToScroll = link.getAttribute('data-cke-saved-href');
            if (!idToScroll?.startsWith('#')) {
                continue;
            }
            link.addEventListener('mousedown', (eventMouse) => {
                const elementSelected = editorInstance.document.$.querySelector(idToScroll);
                if (elementSelected) {
                    elementSelected.scrollIntoView({ block: 'center' });
                }
                eventMouse.stopPropagation();
            }, true);

            link.addEventListener('click', (eventMouse) => {
                eventMouse.stopPropagation();
            });
            link.addEventListener('mouseup', (eventMouse) => {
                eventMouse.stopPropagation();
            });
        }
    }

    public configureInputsAndSelectedAsReadOnly(editorInstance: any) {
        const inputsList = editorInstance.document.$.querySelectorAll('input, textarea, select, radio, checkbox');
        for (const input of inputsList) {

            this.setElementAsDisabled(input);
        }
    }

    public configureInteractiveFormsAsReadOnly(editorInstance: any) {
        const interactiveForms = editorInstance.document.$.querySelectorAll('input, textarea, select, radio, checkbox, a[shape=rect]');
        for (const form of interactiveForms) {
            if (form.hasAttribute('readonly') || form.hasAttribute('disabled')) {
                continue;
            }
            this.setInputsAndSelectAsReadOnlyAndRemainingAsDisabled(form);
        }
    }

    public configureInputsAndSelectedInClausesAsDisabled(editorInstance: any) {
        const clausesList = editorInstance.document.$.querySelectorAll('.read-only-clause');
        clausesList.forEach(clause => {
            const clauseInteractiveForms = clause.querySelectorAll('input, textarea, select, radio, checkbox');
            for (const form of clauseInteractiveForms) {
                if (form.hasAttribute('disabled')) {
                    continue;
                }
                this.setElementAsDisabled(form);
            }
        });
    }

    public removeReadOnlyInputsTextAndSelectEditablesDocument(contentHtml: string): string {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(contentHtml, 'text/html');
        const htmlElement = document.querySelector('html');

        const inputsList = htmlElement.querySelectorAll('input, textarea, select, radio, checkbox');

        inputsList.forEach((input: Element) => {
            this.removeReadOnlyInElement(input);
        });

        return htmlElement.outerHTML;
    }

    private enableSelector(input: any, editorInstance: any, sendDataChange: any, changeContentEmmiter: any,
                           documentPartsForEditor: EditorDocumentParts) {
        input.addEventListener('change', (eventChange) => {
            const textSelect = input.value;
            for (const option of input.options) {
                if (option.getAttribute('value') === textSelect) {
                    option.setAttribute('selected', 'selected');
                } else {
                    option.removeAttribute('selected');
                }
            }
            input.setAttribute('value', textSelect);
            if (sendDataChange) {
                sendDataChange(eventChange, editorInstance, changeContentEmmiter, documentPartsForEditor);
            }
        }, true);
    }

    private setValueInInnerText(input: any) {
        const textAreaText = input.value;
        input.innerText = textAreaText;
        input.innerHTML = textAreaText;
    }

    private isInputRadioOrCheck(input: any): boolean {
        if (input.tagName.toLowerCase() === 'input') {
            const type = input.getAttribute('type');
            return type && type.toLowerCase() === 'checkbox' ||
                type && type.toLowerCase() === 'radio';
        }
        return false;
    }

    private isRadioOrCheck(input: any): boolean {
        return input.tagName.toLowerCase() === 'radio' ||
            input.tagName.toLowerCase() === 'checkbox';
    }

    private isInputOrSelect(input: any): boolean {
        return (input.tagName.toLowerCase() === 'input' && input.getAttribute('type').toLowerCase() !== 'checkbox') ||
            input.tagName.toLowerCase() === 'select';
    }

    private setOnClickAttributeBlankInPlusMinusGroupButtons(input: any): void {

        const regexSuppAddFunction = /\('[^']+'\)/;

        let onclickAttribute = input.getAttribute('onclick');
        if (onclickAttribute) {
            const newOnclickAttribute = onclickAttribute.replace(regexSuppAddFunction, '()');
            input.setAttribute('onclick', newOnclickAttribute);
        }

        onclickAttribute = input.getAttribute('data-cke-pa-onclick');
        if (onclickAttribute) {
            const newOnclickAttribute = onclickAttribute.replace(regexSuppAddFunction, '()');
            input.setAttribute('data-cke-pa-onclick', newOnclickAttribute);
        }
    }

    private setInputsAndSelectAsReadOnlyAndRemainingAsDisabled(input: any): void {
        if (input.tagName.toLowerCase() === 'input' && input.getAttribute('type'.toLowerCase() === 'radio')) {
            return;
        } else if (input.tagName.toLowerCase() === 'a') {
            this.setOnClickAttributeBlankInPlusMinusGroupButtons(input);
            return;
        }

        if (this.isInputOrSelect(input)) {
            input.setAttribute('readonly', true);
        } else {
            input.setAttribute('disabled', true);
        }

        this.setEventListenersAsDisabled(input);
    }

    private setElementAsDisabled(input: any) {
        input.setAttribute('disabled', true);
        this.setEventListenersAsDisabled(input);
    }

    private setEventListenersAsDisabled(input: any) {
        input.addEventListener('mousedown', (eventMouse) => {
            eventMouse.stopPropagation();
        }, true);

        if (input.tagName.toLowerCase() === 'textarea') {
            input.addEventListener('change', () => {
                // Do nothing
            }, true);
        }

        if (input.tagName.toLowerCase() === 'input') {
            input.addEventListener('change', () => {  // Do nothing
            }, true);
        }

        if (input.tagName.toLowerCase() === 'select') {
            input.addEventListener('change', () => {  // Do nothing

            }, true);

            for (let i = 0; i < input.options.length; i++) {
                input.options[i].setAttribute('disabled', true);
            }
        }
    }

    private removeReadOnlyInElement(input: any) {
        input.setAttribute('contenteditable', true);
        input.removeAttribute('readonly');
        input.removeAttribute('disabled');
    }
}
