import { Directive, Inject } from "@angular/core";
import {View, LabeledFieldView, createLabeledInputText, ButtonView, icons, submitHandler,
        ViewCollection, FocusableView, Locale, InputTextView, FocusTracker, FocusCycler, KeystrokeHandler
} from "ckeditor5";

@Directive({
    selector: "app-signature-config",
})
export default class SignatureFormView extends View {

    public roleInputView: LabeledFieldView<InputTextView>;
    public readonly keystrokes = new KeystrokeHandler();
    public readonly focusTracker = new FocusTracker();
    private currentId = "";
    private saveButtonView: ButtonView;
    private cancelButtonView: ButtonView;
    private childViews: ViewCollection<LabeledFieldView<FocusableView> | ButtonView>;
    private readonly focusables = new ViewCollection<FocusableView>();
    private readonly focusCycler: FocusCycler;
    private readonly validators: Array<SignatureFormValidatorCallback>;

    constructor(
        @Inject(Array<SignatureFormValidatorCallback>)
        validators: Array<SignatureFormValidatorCallback>,
        @Inject(Locale) locale?: Locale,
        @Inject(String) defaultValue?: string,
        @Inject(String) defaultId?: string
    ) {
        super(locale);

        this.validators = validators;
        this.roleInputView = this.createRoleInput(
            $localize`:@@PluginFirmasInfoModal:Añadir Rol`,
            defaultValue
        );
        this.currentId = this.generateId(defaultId);
        this.saveButtonView = this.createButton(
            $localize`:@@PluginFirmasInsertarBotonModal:Insertar`,
            icons.check,
            "ck-button-save"
        );

        this.saveButtonView.type = "submit";

        this.cancelButtonView = this.createButton(
            $localize`:@@PluginFirmasCancelarBotonModal:Cancelar`,
            icons.cancel,
            "ck-button-cancel"
        );

        this.cancelButtonView.delegate("execute").to(this, "cancel");

        this.childViews = this.createCollection([
            this.roleInputView,
            this.saveButtonView,
            this.cancelButtonView,
        ]);

        this.focusCycler = new FocusCycler({
            focusables: this.focusables,
            focusTracker: this.focusTracker,
            keystrokeHandler: this.keystrokes,
            actions: {
                // Navigate form fields backwards using the Shift + Tab keystroke.
                focusPrevious: "shift + tab",

                // Navigate form fields forwards using the Tab key.
                focusNext: "tab",
            },
        });

        this.setTemplate({
            tag: "form",
            attributes: {
                class: ["ck", "ck-signature-form"],
                tabindex: "-1",
            },
            children: this.childViews,
        });
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    public override render(): void {
        super.render();

        // Submit the form when the user clicked the save button
        // or pressed enter in the input.
        submitHandler({
            view: this,
        });

        this.childViews.forEach((v) => {
            // Register the view as focusable.
            this.focusables.add(v);

            // Register the view in the focus tracker.
            this.focusTracker.add(v.element!);
        });

        // Start listening for the keystrokes coming from #element.
        this.keystrokes.listenTo(this.element!);
    }

    public isValid(): boolean {
        this.resetFormStatus();

        for (const validator of this.validators) {
            const errorText = validator(this);

            if (errorText) {
                this.roleInputView.errorText = errorText;
                return false;
            }
        }

        return true;
    }

    public resetFormStatus(): void {
        this.roleInputView.errorText = null;
    }

    public get role(): string | null {
        const { element } = this.roleInputView.fieldView;

        if (!element) {
            return null;
        }

        return element.value.trim();
    }

    public set role(value: string | null) {
        const { element } = this.roleInputView.fieldView;

        if (!element) {
            return;
        }

        element.value = !!value ? value : "";
    }

    public get signatureId(): string {
        return this.currentId;
    }

    public set signatureId(value: string | null | undefined) {
        this.currentId = this.generateId(value);
    }

    public focus(): void {
        this.childViews.first?.focus();
    }

    private createRoleInput(label: string, defaultValue?: string): LabeledFieldView<InputTextView> {
        const labeledInput = new LabeledFieldView(
            this.locale,
            createLabeledInputText
        );

        labeledInput.label = label;
        if (defaultValue) {
            labeledInput.fieldView.value = defaultValue;
        }

        labeledInput.fieldView.on("input", (value: any) => {
            this.resetFormStatus();
        });

        return labeledInput;
    }

    private createButton(label: string, icon: string, className: string): ButtonView {
        const button = new ButtonView();

        button.set({
            label,
            icon,
            tooltip: true,
            class: className,
        });

        return button;
    }

    private generateId(currentId: string | null | undefined): string {
        return !!currentId ? currentId : `sign-${crypto.randomUUID()}`;
    }
}

export type SignatureFormValidatorCallback = (form: SignatureFormView) => string | undefined;
